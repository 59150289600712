import { ApprovedKlydo } from '../../../api/klydo';
import studioApiClient from '../../../api/studio';
import useKlydoQuery from '../../../hooks/useKlydoQuery';

export const useKlydosTopFive = () => {
  const staleTime = 5 * 60 * 1000;
  const limit = 5;
  return useKlydoQuery<ApprovedKlydo[]>({
    queryKey: ['TopKlydos', limit],
    queryFn: () => studioApiClient.getTopKlydos(limit),
    staleTime: staleTime,
    refetchOnWindowFocus: false,
    keepPreviousData: true,
  });
};
