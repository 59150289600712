import React from 'react';
import useAuthInitializer from './hooks/useAuthInitializer';
import AuthPage from '../views/SignUp/components/AuthPage';
import firebase from 'firebase/compat/app';
import { SplashScreen } from '../SplashScreen/SplashScreen';

type Props = {
  setAuthUser: (user: firebase.User) => void;
  isAdmin: boolean;
  children: React.ReactNode;
};

const AuthInitializer: React.FC<Props> = ({
  setAuthUser,
  isAdmin,
  children,
}) => {
  const { firebaseAuthLoading, authUser, emailVerified } = useAuthInitializer(
    isAdmin,
    setAuthUser,
  );

  if (firebaseAuthLoading) {
    return <SplashScreen />;
  }

  if (!authUser || (!authUser.emailVerified && !emailVerified)) {
    return <AuthPage />;
  }

  return <>{children}</>;
};

export default AuthInitializer;
