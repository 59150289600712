import { parseCloudinaryUrl } from '../../../utils';

const KlydoFrame: React.FC<{ klydoLoopUrl: string }> = ({ klydoLoopUrl }) => {
  const url = parseCloudinaryUrl({
    url: klydoLoopUrl,
    isStatic: true,
    size: 200,
  });

  return (
    <img
      style={{
        width: '100%',
        height: '100%',
        objectFit: 'cover',
      }}
      loading="eager"
      height={96}
      width={96}
      src={url}
      alt="klydo"
    />
  );
};

export default KlydoFrame;
