import axios, { AxiosProgressEvent } from 'axios';

export type UploadFileInput = {
  file: File | string;
  folder: string;
  upload_preset?: string;
  onUploadProgress?: (progress: number) => void;
};

export const imageTypes = [
  'jpg',
  'gif',
  'avif',
  'apng',
  'png',
  'ico',
  'cur',
  'jpeg',
  'jfif',
  'pjpeg',
  'pjp',
  'tif',
  'webp',
  'bmp',
];

const videoTypes = ['mp4', 'mpge', 'avi', 'mov', 'mkv', 'webm', 'ogg', 'ogv'];

export const uploadFile = async (input: UploadFileInput) => {
  try {
    const response = await axios.post(
      'https://api.cloudinary.com/v1_1/KlydoClock/auto/upload',
      {
        file: input.file,
        folder: input.folder,
        upload_preset: input.upload_preset || 'Bezalel_preset',
      },
      {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
        onUploadProgress: (progressEvent: AxiosProgressEvent) => {
          if (input.onUploadProgress) {
            const percentCompleted = Math.floor(
              (progressEvent.loaded * 100) / (progressEvent?.total || 1),
            );
            input.onUploadProgress(percentCompleted);
          }
        },
      },
    );
    return response.data;
  } catch (error) {
    console.error('Upload error:', error);
    throw new Error('Upload failed');
  }
};

export const getUploadResDetails = (result: any): number => {
  const format: string = result.format;
  var frames: number = NaN;
  if (imageTypes.includes(format)) {
    frames = Number.isNaN(Number(result.pages)) ? 1 : Number(result.pages);
  } else if (videoTypes.includes(format)) {
    frames = Number.isNaN(Number(result.nb_frames))
      ? Number(result.frame_rate) * Number(result.duration)
      : Number(result.nb_frames);
  } else return 0;
  if (
    Number.isNaN(result.height) ||
    Number.isNaN(result.width) ||
    Number.isNaN(frames)
  )
    return 0;
  const h = Number(result.height);
  const w = Number(result.width);
  return (
    (frames * (h <= 1080 ? h : 1080) * (w <= 1080 ? w : 1080) * 4) /
    (1024 * 1024)
  );
};
