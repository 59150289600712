import React from 'react';
import { Avatar, useTheme } from '@mui/material';

const DSPackLogoAvatar = (props: React.ComponentProps<typeof Avatar>) => {
  const { spacing } = useTheme();
  return (
    <Avatar
      {...props}
      sx={{
        ...props.sx,
        width: spacing(25),
        height: spacing(25),
        borderRadius: '50%',
      }}
    />
  );
};

export default DSPackLogoAvatar;
