import { useMemo } from 'react';
import { useKlydoViewers } from './useKlydosViewer';
import { useTheme } from '@mui/material';

export const useKlydoMap = () => {
  const windowHours = 24;
  const center = { lat: 31.771959, lng: 35.217018 };
  const zoom = 8;

  const { spacing } = useTheme();

  const { data: viewers, isError } = useKlydoViewers(windowHours);

  const devices = useMemo(() => {
    if (!viewers) return [];
    return viewers;
  }, [viewers]);

  return {
    devices,
    center,
    zoom,
    isError,
    spacing,
  };
};
