import {
  InfiniteData,
  useMutation,
  useQueryClient,
} from '@tanstack/react-query';
import packApiClient, { DraftPack } from '../../../api/packs';

const useUpdatePackMutation = () => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationKey: ['update-pack'],
    mutationFn: ({
      packId,
      pack,
    }: {
      packId: string;
      pack: Partial<DraftPack>;
    }) => {
      return packApiClient.updatePack(packId, pack);
    },
    onMutate: async ({
      packId,
      pack,
    }: {
      packId: string;
      pack: Partial<DraftPack>;
    }) => {
      queryClient.setQueryData(['pack', packId], pack);

      const matchingQueries = queryClient
        .getQueryCache()
        .findAll({ queryKey: ['packs'] });

      matchingQueries.forEach(({ queryKey }) => {
        queryClient.setQueryData<InfiniteData<DraftPack[]>>(
          queryKey,
          (oldData) => {
            if (!oldData) return oldData;
            return {
              ...oldData,
              pages: oldData.pages.map((page) =>
                page.map((oldPack) =>
                  oldPack.id === packId ? { ...oldPack, ...pack } : oldPack,
                ),
              ),
            };
          },
        );
      });
    },
    onSettled: () =>
      queryClient.refetchQueries({
        queryKey: ['packs'],
      }),
  });
};

export default useUpdatePackMutation;
