import React from 'react';
import { IconButton, IconButtonProps, useTheme } from '@mui/material';

interface DSMenuIconButtonProps extends IconButtonProps {
  component?: React.ElementType;
}

const DSMenuIconButton = React.forwardRef<
  HTMLButtonElement,
  DSMenuIconButtonProps
>((props, ref) => {
  const { spacing, palette } = useTheme();
  return (
    <IconButton
      ref={ref}
      {...props}
      sx={{
        backgroundColor:
          props['aria-expanded'] === 'true'
            ? palette.action.focus
            : 'transparent ',
        borderRadius: spacing(2),
        '&:hover': {
          borderRadius: spacing(2),
        },
      }}
    />
  );
});

export default DSMenuIconButton;
