import DSBox from '../../design-system/DSBox';
import useKlydoPreview from './hooks/useKlydoPreview';
import KlydoLoopUrl from './KlydoLoopUrl';
import Pendulum from './Pendulum';
import ClockView from './ClockView';
import { BaseKlydo } from '../../api/klydo';

type KlydoPreviewProps = {
  klydo: Partial<BaseKlydo>;
  handleFileChange?: (event: React.ChangeEvent<HTMLInputElement>) => void;
  minutesValue?: number;
  hoursValue?: number;
  isAnimationStatic?: boolean;
  isPendulumStatic?: boolean;
};

const KlydoPreview = ({
  klydo,
  minutesValue,
  hoursValue,
  isAnimationStatic,
  isPendulumStatic,
}: KlydoPreviewProps): JSX.Element => {
  const {
    ref,
    backgroundColor,
    loopUrl,
    handsColor,
    pendulumColor,
    pendulumRodColor,
    handHour,
    handMin,
    handHourS,
    handMinS,
    dialsColor,
  } = useKlydoPreview({
    klydo,
    minutesValue,
    hoursValue,
    isAnimationStatic,
  });

  return (
    <div ref={ref}>
      <DSBox
        sx={{
          alignItems: 'center',
          justifyContent: 'center',
          flexDirection: 'column',
          display: 'flex',
          width: '100%',
          height: '50%',
          marginTop: '50%',
        }}
      >
        <DSBox
          sx={{
            backgroundColor: backgroundColor,
            borderRadius: '50%',
            justifyContent: 'center',
            alignItems: 'center',
            flexDirection: 'center',
            display: 'flex',
            width: '80%',
            position: 'absolute',
            height: '50%',
          }}
        >
          {loopUrl && <KlydoLoopUrl loopUrl={loopUrl} />}
          <ClockView
            handHour={handHour}
            handHourS={handHourS}
            handMin={handMin}
            handMinS={handMinS}
            handsColor={handsColor}
            isDialsOn={klydo.dials || false}
            dialsColor={dialsColor}
          />
        </DSBox>
        <Pendulum
          backgroundColor={backgroundColor}
          pendulumColor={pendulumColor}
          rodColor={pendulumRodColor}
          isStatic={isPendulumStatic}
        />
      </DSBox>
    </div>
  );
};
export default KlydoPreview;
