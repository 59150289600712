import { useMutation, useQueryClient } from '@tanstack/react-query';
import packApiClient, { DraftPack } from '../../../api/packs';

const useAddPackMutation = () => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationKey: ['add-packs'],
    mutationFn: (pack: Partial<DraftPack>) => packApiClient.createPack(pack),
    onSuccess: (res) => queryClient.setQueryData(['packs', res.id], res),
  });
};
export default useAddPackMutation;
