import React from 'react';
import { Autocomplete, AutocompleteProps } from '@mui/material';

type DSAutocompleteProps<T> = AutocompleteProps<T, false, false, false>;

const DSAutocomplete = <T,>(props: DSAutocompleteProps<T>) => {
  return <Autocomplete {...props} />;
};

export default DSAutocomplete;
