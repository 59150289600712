import DSBox from '../../../design-system/DSBox';
import PacksGrid from './PackGrid';
import { useNavigate } from 'react-router-dom';
import DSPrimaryButton from '../../../design-system/DSPrimaryButtom';
import { useTheme } from '@mui/material';
import DSTabGradientViewPaper from '../../../design-system/Paper/DSTabGradientViewPaper';
import DSTabHeaderTypography from '../../../design-system/Typography/DSSTabHeaderTypography';

const Packs = () => {
  const navigate = useNavigate();
  const { spacing } = useTheme();
  return (
    <DSTabGradientViewPaper
      sx={{
        display: 'flex',
        flexDirection: 'column',
        margin: spacing(3),
      }}
    >
      <DSBox
        sx={{
          display: 'flex',
          justifyContent: 'space-between',
          flexDirection: 'row',
          alignItems: 'center',
          paddingTop: spacing(5),
          paddingX: spacing(4),
          paddingBottom: spacing(7),
        }}
      >
        <DSTabHeaderTypography>Packs</DSTabHeaderTypography>

        <DSPrimaryButton
          onClick={() => navigate('/edit-pack')}
          sx={{ padding: '14px var(--5, 40px)' }}
        >
          Create
        </DSPrimaryButton>
      </DSBox>
      <DSBox
        sx={{ display: 'flex', alignItems: 'center', flexDirection: 'column' }}
      >
        <PacksGrid />
      </DSBox>
    </DSTabGradientViewPaper>
  );
};

export default Packs;
