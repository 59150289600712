import {
  DraftPack,
  PackReviewStates,
  PackType,
  PackUnion,
} from '../../../api/packs';
import usePackItemMenuButton from '../hooks/usePackItemMenuButton';
import DSBox from '../../../design-system/DSBox';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import DSMenuIconButton from '../../../design-system/DSMenuIconButton';
import DSMenu from '../../../design-system/DSMenu';
import DSMenuItem from '../../../design-system/DSMenuItem';

type PackItemMenuButtonProps = {
  onDelete: (packId: string) => void;
  onSubmit: ({ packId, pack }: { packId: string; pack: DraftPack }) => void;
  pack: PackUnion;
};
const PackItemMenuButton = ({
  onDelete,
  pack,
  onSubmit,
}: PackItemMenuButtonProps) => {
  const {
    anchorElement,
    isMenuOpen,
    handleMenuClick,
    handleMenuClose,
    handleEditPack,
    isButtonDisabled,
  } = usePackItemMenuButton(pack);
  return (
    <DSBox>
      <DSMenuIconButton
        onClick={handleMenuClick}
        aria-controls={isMenuOpen ? 'basic-menu' : undefined}
        aria-haspopup="true"
        aria-expanded={isMenuOpen ? 'true' : undefined}
      >
        <MoreVertIcon />
      </DSMenuIconButton>
      <DSMenu
        anchorEl={anchorElement}
        open={isMenuOpen}
        onClose={handleMenuClose}
        sx={{
          mt: 3,
        }}
      >
        <DSMenuItem
          disabled={isButtonDisabled}
          onClick={() => {
            if (pack.type !== PackType.Draft) {
              return;
            }
            onSubmit({
              packId: pack.id,
              pack: {
                ...pack,
                klydos: undefined,
                reviewStatus: { state: PackReviewStates.Pending },
              },
            });
          }}
        >
          Submit
        </DSMenuItem>
        <DSMenuItem disabled={isButtonDisabled} onClick={handleEditPack}>
          Edit Pack
        </DSMenuItem>
        <DSMenuItem
          disabled={isButtonDisabled}
          onClick={() => {
            onDelete(pack.id);
          }}
        >
          Delete Pack
        </DSMenuItem>
      </DSMenu>
    </DSBox>
  );
};

export default PackItemMenuButton;
