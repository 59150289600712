import { Theme } from '../types';

//TODO: think about the secter key
export const GOOGLE_MAPS_API_KEY = 'AIzaSyC5XG3LtScVeWzLYbiYE-JXY4GLFRnGduQ';
export const MAX_MEMORY_USAGE = 1200;
export const DRAWER_WIDTH = 34;
export const APPBAR_HIGHT = 10;
export const DEFAULT_KLYDO_THEME: Theme = {
  handsColor: 'rgba(168, 168, 168, 1)',
  backgroundColor: 'rgba(73, 72, 72, 1)',
  pendulumColor: 'rgba(168, 168, 168, 1)',
  pendulumRodColor: 'rgba(168, 168, 168, 1)',
  dialsColor: 'rgba(217, 217, 217, 1)',
};
