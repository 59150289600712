import { useState } from 'react';
import firebaseService from '../../../firebase/firebaseService';

const useAuthPage = () => {
  const [isRegister, setIsRegister] = useState(false);
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [passwordError, setPasswordError] = useState('');

  const validatePassword = (password: string) => {
    setPassword(password);
    if (password.length < 8) {
      setPasswordError('Password must be at least 8 characters.');
    } else if (!/(?=.*[a-z])(?=.*[A-Z])/.test(password)) {
      setPasswordError(
        'Password must include uppercase and lowercase letters.',
      );
    } else if (!/(?=.*\d)/.test(password)) {
      setPasswordError('Password must include at least one number.');
    } else if (!/(?=.*[@$!%*?&])/.test(password)) {
      setPasswordError('Password must include at least one special character.');
    } else {
      setPasswordError('');
    }
  };

  const handlePrimaryButtonPress = async () => {
    try {
      if (isRegister) {
        if (passwordError) {
          alert(
            'Your password must meet the specified requirements before proceeding.',
          );
          return;
        }
        await firebaseService.createUserWithEmailAndPassword(email, password);
      } else {
        await firebaseService.signInWithEmailAndPassword(email, password);
      }
    } catch (error) {
      console.error('Authentication error:', error);
    }
  };

  const handleForgotPassword = () => {
    if (!email) {
      alert('Please enter your email address first.');
      return;
    }
    firebaseService.sendPasswordResetEmail(email);
  };

  const handleGoogleSignIn = async () => {
    try {
      await firebaseService.signInWithGoogle();
    } catch (error) {
      console.error('Google Sign-In error:', error);
    }
  };

  const handleVerifyEmail = async () => {
    try {
      await firebaseService.resendVerificationEmail();
    } catch (error) {
      console.error('Verification email error:', error);
    }
  };

  const handleTabChange = (
    _: React.ChangeEvent<unknown>,
    newValue: boolean,
  ) => {
    setIsRegister(newValue);
  };

  return {
    isRegister,
    setIsRegister,
    email,
    setEmail,
    password,
    setPassword,
    passwordError,
    validatePassword,
    handlePrimaryButtonPress,
    handleForgotPassword,
    handleGoogleSignIn,
    handleTabChange,
    handleVerifyEmail,
  };
};

export default useAuthPage;
