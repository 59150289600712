import { useState } from 'react';
import firebase from 'firebase/compat/app';
import { User } from '../../api/user';

const useAppContextProvider = () => {
  const [user, setUser] = useState<User | null>(null);
  const [authUser, setAuthUser] = useState<firebase.User | null>(null);
  const [isAdmin, setIsAdmin] = useState(false);
  const [isImpersonating, setIsImpersonating] = useState(false);

  const getInfoFromToken = async (
    user: firebase.User | null,
    onValidateInfo: (isImpersonating: boolean, isAdmin: boolean) => void,
  ): Promise<void> => {
    try {
      if (!user) {
        onValidateInfo(false, false);
        return;
      }

      const idTokenResult = await user.getIdTokenResult();
      const isImpersonating = idTokenResult.signInProvider === 'custom';
      const isAdmin = idTokenResult.claims?.role === 'admin';
      onValidateInfo(isImpersonating, isAdmin);
    } catch (error) {
      console.error('Error validating admin role:', error);
      throw error;
    }
  };

  return {
    user,
    setUser,
    authUser,
    setAuthUser: (user: firebase.User) => {
      setAuthUser(user);
      const handleValidation = (isImpersonating: boolean, isAdmin: boolean) => {
        setIsAdmin(isAdmin);
        setIsImpersonating(isImpersonating);
      };

      getInfoFromToken(user, handleValidation);
    },
    isAdmin,
    setIsAdmin,
    isImpersonating,
    setIsImpersonating,
  };
};

export default useAppContextProvider;
