import { useState, useCallback } from 'react';
import useAddPackMutation from '../hooks/useAddPackMutation';
import { getUserId } from '../../../api/baseRequest';
import { uploadFile } from '../../../utils/uploadFile';
import { useTheme } from '@mui/material';
import { DraftPack, PackReviewStates } from '../../../api/packs';
import { KlydoUnion } from '../../../api/klydo';
import { useLocation, useNavigate } from 'react-router-dom';

const useCreatePack = () => {
  const pack = (useLocation().state?.pack as DraftPack) || undefined;
  const { mutate: addPackMutation } = useAddPackMutation();
  const { spacing, palette } = useTheme();
  const [name, setName] = useState<string>(pack?.name || '');
  const [description, setDescription] = useState<string>(
    pack?.description || '',
  );
  const [logo, setLogo] = useState<string>(pack?.logo || '');
  const [cover, setCover] = useState<string>(pack?.cover || '');
  const [klydos, setKlydos] = useState<KlydoUnion[]>([]);
  const [isUploading, setIsUploading] = useState(false);
  const [isPickModalOpen, setIsPickModalOpen] = useState(false);
  const updatedAt = pack?.updatedAt;
  const navigate = useNavigate();

  const isSubmitDisable =
    !name.trim() ||
    !cover ||
    isUploading ||
    !klydos.length ||
    !description ||
    !logo;

  const handleOpenPickModal = () => {
    setIsPickModalOpen(true);
  };

  const handleClosePickModal = () => {
    setIsPickModalOpen(false);
  };

  const handleBack = () => {
    navigate('/packs');
  };

  const handlePickKlydos = (selectedKlydos: KlydoUnion[]) => {
    setKlydos([...selectedKlydos]);
  };

  const handleFileUpload = async (
    folder: string,
    setImage: (url: string) => void,
    file: File,
  ) => {
    setIsUploading(true);

    const reader = new FileReader();
    reader.onloadend = () => {
      uploadFile({
        file: reader.result as string,
        folder: folder,
        upload_preset: 'Bezalel_preset',
      })
        .then((response) => {
          setImage(response.secure_url);
        })
        .catch((error) => {
          console.error('Upload error:', error);
          alert('Failed to upload the file. Please try again.');
        })
        .finally(() => {
          setIsUploading(false);
        });
    };

    reader.readAsDataURL(file);
  };

  const handleFileChange = useCallback(
    (
      folder: string,
      setImage: (url: string) => void,
      event: React.ChangeEvent<HTMLInputElement>,
    ) => {
      const file = event.target.files?.[0];
      if (!file) return;

      handleFileUpload(folder, setImage, file);
    },
    [],
  );

  const handleSubmitPack = useCallback(async () => {
    if (!name.trim()) {
      alert('Error: Pack name is required.');
      return;
    }

    if (!cover) {
      alert('Error: Pack cover is required.');
      return;
    }

    try {
      const creatorId = await getUserId();
      addPackMutation(
        {
          name,
          description,
          logo,
          creatorId,
          reviewStatus: {
            state: PackReviewStates.Draft,
          },
          klydoIds: klydos
            .map((klydo) => klydo.id)
            .filter((id): id is string => id !== undefined),
          ...(cover && { cover }),
        },
        {
          onSuccess: () => {
            navigate('/packs');
          },
        },
      );
    } catch (error) {
      console.error('Failed to create pack:', error);
    }
  }, [name, logo, description, cover, addPackMutation]);

  return {
    name,
    setName,
    description,
    setDescription,
    logo,
    setLogo,
    cover,
    setCover,
    updatedAt,
    handleBack,
    handleFileChange,
    handleSubmitPack,
    handleOpenPickModal,
    handleClosePickModal,
    handlePickKlydos,
    isPickModalOpen,
    klydos,
    isUploading,
    spacing,
    palette,
    isSubmitDisable,
  };
};

export default useCreatePack;
