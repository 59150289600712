import firebase from 'firebase/compat/app';
import useUserMutation from '../hooks/useUserMutation';
import { User } from '../../../api/user';

const useSignupForm = (
  authUser: firebase.User,
  setUser: (user: User) => void,
) => {
  const { mutate } = useUserMutation(authUser.uid, {
    onSuccess: (user: User) => {
      setUser(user);
    },
    optimistic: false,
  });

  const parsedAuthPhotoUrl =
    authUser?.photoURL?.replace('s96-c', 's400-c') ||
    'https://ui-avatars.com/api/?name=User';

  const handleSave = (updateUser: Partial<User>) => {
    mutate({ ...updateUser });
  };

  return {
    name: authUser.displayName || '',
    email: authUser.email || '',
    photoUrl: parsedAuthPhotoUrl,
    description: '',
    country: '',
    socialMedia: [],
    handleSave,
  };
};

export default useSignupForm;
