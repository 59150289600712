import KlydoPreview from '../../../components/klydoPreview/KlydoPreview';
import React from 'react';
import DSTextField from '../../../design-system/DSTextField';
import useCreateKlydo from '../hooks/useCreateKlydo';
import KlydoColorStack from '../ColorPicker/components/KlydoColorStack';
import DSBox from '../../../design-system/DSBox';
import { DSTypography } from '../../../design-system/Typography';
import ArrowBack from '@mui/icons-material/ArrowBack';
import { KlydoReviewStates } from '../../../api/klydo';
import KlydoStatus from '../KlydosList/components/KlydoStatus';
import DSTabHeaderTypography from '../../../design-system/Typography/DSSTabHeaderTypography';
import DSLabelTypography from '../../../design-system/Typography/DSLabelTypography';
import KlydoFileInput from './KlydoFileInput';
import DSPrimaryButton from '../../../design-system/DSPrimaryButtom';
import DSGradientViewPaper from '../../../design-system/Paper/DSGradientViewPaper';

const CreateKlydo = () => {
  const {
    handleCreateKlydo,
    setSelectedTheme,
    klydoTitle,
    setKlydoTitle,
    handleFileChange,
    klydoUrl,
    suggestedColors,
    setSuggestedColors,
    selectedTheme,
    isDialsBlocked,
    handleKlydoThemeChange,
    isLoadingUrl,
    uploadProgress,
    klydoSecureUrl,
    memoryUsage,
    isCreateButtonDisabled,
    shouldShowWarningModal,
    handleBlockDialsClick,
    setShouldShowWarningModal,
    handleConfirmBlockDials,
    minutesValue,
    setMinutesValue,
    hoursValue,
    setHoursValue,
    isDialsHidden,
    handleHideDialsClick,
    isLoadingThemes,
    spacing,
    handleRemoveFile,
    fileName,
  } = useCreateKlydo();

  return (
    <DSGradientViewPaper
      sx={{
        alignItems: 'center',
        flexDirection: 'column',
        justifyContent: 'space-between',
        margin: { sm: spacing(2), md: spacing(3) },
        paddingTop: spacing(4),
        paddingX: { sm: spacing(3), md: spacing(4) },
      }}
    >
      <DSBox sx={{ display: 'flex', gap: spacing(1) }}>
        <ArrowBack
          sx={{
            fontSize: spacing(6),
          }}
        />
        <DSTabHeaderTypography>Edit Klydo</DSTabHeaderTypography>
      </DSBox>
      <DSBox
        sx={{
          flexDirection: { sm: 'column', md: 'row' },
          justifyContent: 'space-between',
          display: 'flex',
          width: '100%',
        }}
      >
        <DSBox
          sx={{
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'flex-start',
            width: { sm: spacing(45), md: spacing(56) },
            gap: spacing(3),
          }}
        >
          <DSBox
            sx={{
              alignItems: 'center',
              display: 'flex',
              justifyContent: 'space-between',
              flexDirection: 'row',
            }}
          >
            <DSTypography
              sx={{ marginTop: spacing(1), color: 'text.secondary' }}
            >
              Last Update
            </DSTypography>
            <KlydoStatus state={KlydoReviewStates.Draft} />
          </DSBox>
          <DSBox
            sx={{ display: 'flex', flexDirection: 'column', gap: spacing(1) }}
          >
            <DSLabelTypography>
              *Choose a public name for your klydo
            </DSLabelTypography>
            <DSTextField
              placeholder="Name your Klydo"
              value={klydoTitle}
              fullWidth
              required
              slotProps={{
                input: {
                  inputProps: { maxLength: 70 },
                },
              }}
              onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                setKlydoTitle(event.target.value);
              }}
            />
          </DSBox>
          <KlydoFileInput
            onFileChange={handleFileChange}
            memoryUsage={memoryUsage}
            klydoSecureUrl={klydoSecureUrl}
            onRemoveFile={handleRemoveFile}
            fileName={fileName}
            isLoadingUrl={isLoadingUrl}
          />
          <DSBox
            sx={{ display: 'flex', flexDirection: 'column', gap: spacing(1) }}
          >
            <DSLabelTypography>
              *Choose a color palette for your Klydo
            </DSLabelTypography>
            <KlydoColorStack
              themes={suggestedColors}
              setTheme={setSuggestedColors}
              onChangeColor={handleKlydoThemeChange}
              isLoadingThemes={isLoadingThemes}
            />
          </DSBox>
          <DSPrimaryButton
            disabled={isCreateButtonDisabled}
            onClick={handleCreateKlydo}
            sx={{
              width: '50%',
              display: 'flex',
              alignSelf: 'center',
              padding: '6px 16px',
            }}
          >
            Submit To Review
          </DSPrimaryButton>
        </DSBox>

        <DSBox
          sx={{
            marginTop: spacing(10),
            width: { sm: '350px', md: '490px' },
            height: { sm: '560px', md: '739px' },
            position: 'relative',
            borderRadius: '34.827px',
            backgroundColor: '#2D2D2D',
            padding: '40.971px var(--6, 48px)',
          }}
        >
          <KlydoPreview
            klydo={{
              loopUrl: klydoUrl,
              theme: selectedTheme,
              dials: !isDialsHidden,
              klydoSecureUrl: klydoSecureUrl,
            }}
            minutesValue={minutesValue}
            hoursValue={hoursValue}
            handleFileChange={handleFileChange}
            isPendulumStatic={true}
          />
        </DSBox>
      </DSBox>
    </DSGradientViewPaper>
  );
};

export default CreateKlydo;
