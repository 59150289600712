import studioApiClient, { KlydosViewersResult } from '../../../api/studio';
import useKlydoQuery from '../../../hooks/useKlydoQuery';

export const useKlydoViewers = (windowHours: number) => {
  const staleTime = 5 * 60 * 1000;
  return useKlydoQuery<KlydosViewersResult[]>({
    queryKey: ['klydoViewers', windowHours],
    queryFn: () => studioApiClient.getKlydoViewers(windowHours),
    staleTime: staleTime,
    refetchOnWindowFocus: false,
    keepPreviousData: true,
  });
};
