import { useContext, useEffect } from 'react';
import { AppContext } from '../../../contexts/AppContext';
import firebaseService from '../../../firebase/firebaseService';
import { useNavigate } from 'react-router-dom';
import { useTheme } from '@mui/material';

const useProfile = () => {
  const { user } = useContext(AppContext);
  const { spacing } = useTheme();
  const navigate = useNavigate();

  const handleLogout = async () => {
    try {
      await firebaseService.signOut();
    } catch (error) {
      console.error('Error signing out:', error);
    }
  };

  const handleEitProfile = () => {
    navigate('/edit-profile');
  };

  useEffect(() => {
    if (!user) {
      handleLogout();
    }
  }, [user]);

  const { name, email, photoUrl, description, country, socialMedia } = user;

  return {
    handleLogout,
    handleEitProfile,
    spacing,
    name,
    email,
    photoUrl,
    description,
    country,
    socialMedia,
  };
};

export default useProfile;
