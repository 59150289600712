import React from 'react';
import { Card, useTheme } from '@mui/material';

const DSPackCard = (props: React.ComponentProps<typeof Card>) => {
  const { spacing } = useTheme();
  return (
    <Card
      {...props}
      sx={{
        ...props.sx,
        padding: spacing(2),
        width: spacing(40),
        height: spacing(59),
        borderRadius: spacing(4),
        backgroundColor: 'var(--background-paper-elevation-5, #2A2A2A)',
        boxShadow:
          '0px 1px 14px 0px rgba(0, 0, 0, 0.12), 0px 5px 8px 0px rgba(0, 0, 0, 0.14), 0px 3px 5px -1px rgba(0, 0, 0, 0.20)',
      }}
    />
  );
};

export default DSPackCard;
