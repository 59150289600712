import { useEffect, useState } from 'react';
import firebase from 'firebase/compat/app';
import firebaseService from '../../firebase/firebaseService';

const useAuthInitializer = (
  isAdmin: boolean,
  setAuthUser: (user: firebase.User) => void,
) => {
  const [firebaseAuthLoading, setFirebaseAuthLoading] = useState(true);
  const [authUser, setLocalAuthUser] = useState<firebase.User | null>(null);
  const [emailVerified, setEmailVerified] = useState(false);

  useEffect(() => {
    const checkAdminRole = async () => {
      if ((authUser?.emailVerified && !emailVerified) || isAdmin) {
        setEmailVerified(true);
      }
    };
    checkAdminRole();
  }, [authUser, emailVerified]);

  useEffect(() => {
    firebaseService.onAuthStateChanged((user) => {
      if (user) {
        setAuthUser(user);
      }
      setLocalAuthUser(user);
      setFirebaseAuthLoading(false);
    });
  }, [setAuthUser]);

  return {
    firebaseAuthLoading,
    authUser,
    emailVerified,
    setEmailVerified,
  };
};

export default useAuthInitializer;
