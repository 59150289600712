import DSBox from '../../../../design-system/DSBox';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import useKlydoItemMenuButton from '../hooks/useKlydoItemMenuButton';
import {
  DraftKlydo,
  KlydoReviewStates,
  KlydoType,
  KlydoUnion,
} from '../../../../api/klydo';
import DSMenuIconButton from '../../../../design-system/DSMenuIconButton';
import DSMenu from '../../../../design-system/DSMenu';
import DSMenuItem from '../../../../design-system/DSMenuItem';

type KlydoItemMenuButtonProps = {
  onDelete: (klydoId: string) => void;
  onSubmit: (klydo: DraftKlydo) => void;
  klydo: KlydoUnion;
};
const KlydoItemMenuButton = ({
  onDelete,
  klydo,
  onSubmit,
}: KlydoItemMenuButtonProps) => {
  const { anchorElement, isMenuOpen, handleMenuClick, handleMenuClose } =
    useKlydoItemMenuButton();
  return (
    <DSBox>
      <DSMenuIconButton
        onClick={handleMenuClick}
        aria-controls={isMenuOpen ? 'basic-menu' : undefined}
        aria-haspopup="true"
        aria-expanded={isMenuOpen ? 'true' : undefined}
      >
        <MoreVertIcon />
      </DSMenuIconButton>
      <DSMenu
        anchorEl={anchorElement}
        open={isMenuOpen}
        onClose={handleMenuClose}
        sx={{
          mt: 4,
        }}
      >
        <DSMenuItem
          onClick={() => onSubmit(klydo as DraftKlydo)}
          disabled={
            klydo?.type === KlydoType.Approved ||
            klydo?.reviewStatus?.state === KlydoReviewStates.Pending
          }
        >
          Submit
        </DSMenuItem>
        <DSMenuItem>Add to pack</DSMenuItem>
        <DSMenuItem
          onClick={() => {
            onDelete(klydo.id);
          }}
        >
          Delete klydo
        </DSMenuItem>
      </DSMenu>
    </DSBox>
  );
};

export default KlydoItemMenuButton;
