import { useCallback, useEffect, useRef, useState } from 'react';
import { Theme } from '../../../types';
import { getUploadResDetails, uploadFile } from '../../../utils/uploadFile';
import { useNavigate } from 'react-router-dom';
import studioApiClient from '../../../api/studio';
import { MAX_MEMORY_USAGE } from '../../../consts/general';
import { DraftKlydo, KlydoReviewStates } from '../../../api/klydo';
import useAddKlydoMutation from './useAddKlydoMutation';
import { useTheme } from '@mui/material';

const useCreateKlydo = () => {
  const { mutate: addKlydoMutation } = useAddKlydoMutation();
  const { spacing, palette } = useTheme();
  const [klydoTitle, setKlydoTitle] = useState('');
  const [klydoUrl, setKlydoUrl] = useState('');
  const [klydoSecureUrl, setKlydoSecureUrl] = useState('');
  const [suggestedColors, setSuggestedColors] = useState<Theme[]>();
  const [selectedTheme, setSelectedTheme] = useState<Theme>();
  const [isDialsBlocked, setIsDialsBlocked] = useState(false);
  const [isLoadingUrl, setIsLoadingUrl] = useState(false);
  const [memoryUsage, setMemoryUsage] = useState(0);
  const [uploadProgress, setUploadProgress] = useState(0);
  const [shouldShowWarningModal, setShouldShowWarningModal] = useState(false);
  const [minutesValue, setMinutesValue] = useState(0);
  const [hoursValue, setHoursValue] = useState(0);
  const [isDialsHidden, setIsDialsHidden] = useState(false);
  const [isLoadingThemes, setIsLoadingThemes] = useState(false);
  const [fileName, setFileName] = useState<string>('');
  const abortControllerRef = useRef<AbortController | null>(null);
  const navigate = useNavigate();

  useEffect(() => {
    return () => {
      if (abortControllerRef.current) {
        abortControllerRef.current.abort();
      }
    };
  }, []);

  const handleFileUpload = async (fileUrl: string) => {
    uploadFile({
      file: fileUrl,
      folder: 'gifs',
      upload_preset: 'Bezalel_preset',
      onUploadProgress: (progress) => {
        setIsLoadingUrl(true);
        setUploadProgress(progress);
      },
    })
      .then((response) => {
        abortControllerRef.current = new AbortController();
        setMemoryUsage(getUploadResDetails(response));
        setKlydoSecureUrl(response.secure_url);
        setIsLoadingUrl(false);
        setIsLoadingThemes(true);
        studioApiClient
          .getSuggestedKlydoPallete(
            response.secure_url,
            abortControllerRef.current.signal,
          )
          .then((res) => {
            setSuggestedColors(res);
            setSelectedTheme(res[0]);
            setIsLoadingThemes(false);
          })
          .catch((error) => {
            console.error('Process image error:', error);
          });
      })
      .catch((error) => {
        console.error('Upload error:', error);
        // setWarning("Upload failed!");
      })
      .finally(() => {
        // setUploadingGif(false);
      });
  };

  const handleFileChange = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      const file = event.target.files?.[0];
      if (!file) return;
      const validTypes = ['video/mp4', 'image/gif'];
      if (!validTypes.includes(file.type)) {
        alert('Invalid file type. Only MP4 and GIF files are allowed.');
        return;
      }
      const reader = new FileReader();
      reader.onload = () => {
        setFileName(file.name);
        setKlydoUrl(reader.result as string);
        setKlydoSecureUrl('');
        handleFileUpload(reader.result as string);
      };
      reader.readAsDataURL(file);
    },
    [],
  );

  const handleRemoveFile = useCallback(() => {
    console.log('handle remove file');
    if (abortControllerRef.current) {
      abortControllerRef.current.abort();
    }
    setKlydoUrl('');
    setKlydoSecureUrl('');
    setSuggestedColors(undefined);
    setSelectedTheme(undefined);
  }, []);

  const handleKlydoThemeChange = useCallback(
    (value: number) => {
      if (suggestedColors) {
        setSelectedTheme(suggestedColors[value]);
      }
    },
    [suggestedColors],
  );

  const handleCreateKlydo = useCallback(() => {
    const klydo: Partial<DraftKlydo> = {
      name: klydoTitle,
      loopUrl: klydoSecureUrl,
      theme: selectedTheme,
      reviewStatus: {
        state: KlydoReviewStates.Draft,
        message: '',
      },
    };
    addKlydoMutation(klydo, {
      onSuccess: () => {
        navigate(`/klydos`);
      },
    });
  }, [klydoTitle, klydoUrl, selectedTheme, isDialsBlocked]);

  const handleBlockDialsClick = useCallback(() => {
    if (!isDialsBlocked) {
      setShouldShowWarningModal(true);
    } else {
      setIsDialsBlocked(!isDialsBlocked);
    }
  }, [isDialsBlocked]);

  const handleConfirmBlockDials = useCallback(() => {
    setIsDialsBlocked((prev) => !prev);
    setShouldShowWarningModal(false);
  }, []);

  const handleHideDialsClick = useCallback(() => {
    setIsDialsHidden((prev) => !prev);
  }, []);

  const isCreateButtonDisabled =
    !klydoTitle || !klydoSecureUrl || memoryUsage > MAX_MEMORY_USAGE;

  return {
    handleCreateKlydo,
    klydoTitle,
    setKlydoTitle,
    handleFileChange,
    klydoUrl,
    suggestedColors,
    setSuggestedColors,
    selectedTheme,
    setSelectedTheme,
    isDialsBlocked,
    handleKlydoThemeChange,
    isLoadingUrl,
    klydoSecureUrl,
    memoryUsage,
    isCreateButtonDisabled,
    uploadProgress,
    shouldShowWarningModal,
    handleBlockDialsClick,
    setShouldShowWarningModal,
    handleConfirmBlockDials,
    minutesValue,
    setMinutesValue,
    hoursValue,
    setHoursValue,
    isDialsHidden,
    handleHideDialsClick,
    isLoadingThemes,
    spacing,
    palette,
    handleRemoveFile,
    fileName,
  };
};

export default useCreateKlydo;
