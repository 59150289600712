import { Outlet } from 'react-router-dom';
import DSBox from './design-system/DSBox';
import NavigationDrawer from './components/navigationDrawer/NavigationDrawer';
import { NavigationDrawerItem } from './types';
import Packs from './views/Packs/components/Packs';
import Dashboard from './views/Dashboard/components/Dashboard';
import Klydos from './views/Klydos/KlydosList/components/Klydos';
import AppBar from './components/AppBar/components/AppBar';
import { useTheme } from '@mui/material';
import { APPBAR_HIGHT, DRAWER_WIDTH } from './consts/general';

export const navigationDrawerPages: NavigationDrawerItem[] = [
  { title: 'Dashboard', route: '/dashboard', element: <Dashboard /> },
  { title: 'Klydos', route: '/klydos', element: <Klydos /> },
  { title: 'Packs', route: '/packs', element: <Packs /> },
];

const MainLayout = () => {
  const { spacing, palette } = useTheme();
  return (
    <DSBox
      sx={{
        backgroundColor: palette.background.default,
      }}
    >
      <NavigationDrawer pages={navigationDrawerPages} />
      <AppBar />

      <DSBox
        sx={{
          flexGrow: 1,
          marginTop: APPBAR_HIGHT,
          marginLeft: { md: spacing(DRAWER_WIDTH) },
          backgroundColor: palette.background.default,
        }}
      >
        <Outlet />
      </DSBox>
    </DSBox>
  );
};

export default MainLayout;
