import GoogleIcon from '@mui/icons-material/Google';
import logo from '../../../images/klydo-yellow.png';
import { KLYDO_BLACK, KLYDO_WHITE, KLYDO_YELLOW } from '../../../consts/colors';
import DSBox from '../../../design-system/DSBox';
import DSIconButton from '../../../design-system/DSIconButton';
import { DSTypography } from '../../../design-system/Typography';
import DSTextField from '../../../design-system/DSTextField';
import DSDivider from '../../../design-system/DSDivider';
import DSTabs from '../../../design-system/DSTabs';
import DSTab from '../../../design-system/DSTab';
import DSPrimaryButton from '../../../design-system/DSPrimaryButtom';
import useAuthPage from '../hooks/useAuthPage';

const AuthPage = () => {
  const {
    isRegister,
    email,
    password,
    passwordError,
    validatePassword,
    handlePrimaryButtonPress,
    handleForgotPassword,
    handleGoogleSignIn,
    handleTabChange,
    setEmail,
    handleVerifyEmail,
  } = useAuthPage();

  return (
    <DSBox
      sx={{
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
        minHeight: '100vh',
        backgroundColor: KLYDO_BLACK,
        color: KLYDO_WHITE,
        gap: 2,
        p: 3,
      }}
    >
      <DSBox sx={{ textAlign: 'center', mb: 3 }}>
        <img src={logo} alt="Klydo Clock Logo" style={{ width: 200 }} />
      </DSBox>

      <DSTabs
        value={isRegister}
        onChange={handleTabChange}
        sx={{
          marginBottom: 2,
          '.MuiTabs-flexContainer': {
            borderBottom: '1px solid #555',
          },
          '.MuiTab-root': {
            color: '#888',
            '&.Mui-selected': {
              color: KLYDO_YELLOW,
              fontWeight: 'bold',
            },
          },
        }}
      >
        <DSTab label="Login" value={false} />
        <DSTab label="Register" value={true} />
      </DSTabs>

      <DSBox
        sx={{
          display: 'flex',
          flexDirection: 'column',
          gap: 2,
          width: '100%',
          maxWidth: 400,
        }}
      >
        <DSTextField
          label="Email"
          variant="outlined"
          fullWidth
          value={email}
          onChange={(e) => setEmail(e.target.value)}
          InputProps={{
            style: { color: '#fff' },
          }}
          InputLabelProps={{
            style: { color: '#888' },
          }}
          sx={{
            '& .MuiOutlinedInput-root': {
              '& fieldset': { borderColor: '#555' },
              '&:hover fieldset': { borderColor: KLYDO_YELLOW },
              '&.Mui-focused fieldset': { borderColor: KLYDO_YELLOW },
            },
          }}
        />

        <DSTextField
          label="Password"
          variant="outlined"
          type="password"
          fullWidth
          value={password}
          onChange={(e) => validatePassword(e.target.value)}
          InputProps={{
            style: { color: '#fff' },
          }}
          InputLabelProps={{
            style: { color: '#888' },
          }}
          sx={{
            '& .MuiOutlinedInput-root': {
              '& fieldset': { borderColor: '#555' },
              '&:hover fieldset': { borderColor: '#FFD700' },
              '&.Mui-focused fieldset': { borderColor: '#FFD700' },
            },
          }}
        />

        {passwordError && isRegister && password && (
          <DSTypography sx={{ color: 'red', fontSize: '0.875rem' }}>
            {passwordError}
          </DSTypography>
        )}

        {!isRegister && (
          <DSTypography
            onClick={handleForgotPassword}
            sx={{
              color: KLYDO_YELLOW,
              textAlign: 'right',
              cursor: 'pointer',
              textDecoration: 'underline',
            }}
          >
            Forgot Password?
          </DSTypography>
        )}
        {isRegister && (
          <DSTypography
            onClick={handleVerifyEmail}
            sx={{
              color: KLYDO_YELLOW,
              textAlign: 'right',
              cursor: 'pointer',
              textDecoration: 'underline',
            }}
          >
            resend email verification
          </DSTypography>
        )}
      </DSBox>

      <DSPrimaryButton onClick={handlePrimaryButtonPress}>
        {isRegister ? 'Join for Free' : 'Login'}
      </DSPrimaryButton>

      <DSDivider sx={{ my: 2, width: '100%', maxWidth: 400 }}>
        <DSTypography sx={{ color: KLYDO_WHITE }}>
          or continue with
        </DSTypography>
      </DSDivider>

      <DSIconButton
        onClick={handleGoogleSignIn}
        sx={{
          backgroundColor: '#fff',
          color: '#000',
          '&:hover': { backgroundColor: '#f5f5f5' },
        }}
      >
        <GoogleIcon />
      </DSIconButton>
    </DSBox>
  );
};

export default AuthPage;
