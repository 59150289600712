import React from 'react';
import { Button, useTheme } from '@mui/material';

const DSSecondaryButton = React.forwardRef<
  HTMLButtonElement,
  React.ComponentProps<typeof Button>
>((props, ref) => {
  const { spacing, palette } = useTheme();

  return (
    <Button
      ref={ref}
      color="secondary"
      {...props}
      sx={{
        ...props.sx,
        color: palette.secondary.contrastText,
        borderRadius: 50,
        fontFamily: 'Gilroy-SemiBold',
        fontWeight: 500,
        lineHeight: spacing(3),
        fontSize: spacing(2),
      }}
    />
  );
});

export default DSSecondaryButton;
