import React from 'react';
import LanguageIcon from '@mui/icons-material/Language';
import HighlightOffIcon from '@mui/icons-material/HighlightOff';
import QueryBuilderOutlinedIcon from '@mui/icons-material/QueryBuilderOutlined';
import { PackReviewStates } from '../../../api/packs';
import DSBox from '../../../design-system/DSBox';
import { DSTypography } from '../../../design-system/Typography';
import { useTheme } from '@mui/material';
import {
  DRAFT,
  PENDING,
  PUBLISHED_APPROVED,
  REJECTED,
} from '../../../consts/colors';
import { ReactComponent as DraftIcon } from '../../../design-system/Icons/DraftIcon.svg';
type PackStatusProps = {
  state: PackReviewStates;
};

const getStatusColor = (state: PackReviewStates) => {
  switch (state) {
    case PackReviewStates.Published:
      return PUBLISHED_APPROVED;
    case PackReviewStates.Pending:
      return PENDING;
    case PackReviewStates.Rejected:
      return REJECTED;
    case PackReviewStates.Draft:
      return DRAFT;
    default:
      return 'default';
  }
};

const statusConfig = {
  [PackReviewStates.Published]: {
    icon: <LanguageIcon />,
    color: getStatusColor(PackReviewStates.Published),
  },
  [PackReviewStates.Pending]: {
    icon: <QueryBuilderOutlinedIcon />,
    color: getStatusColor(PackReviewStates.Pending),
  },
  [PackReviewStates.Rejected]: {
    icon: <HighlightOffIcon />,
    color: getStatusColor(PackReviewStates.Rejected),
  },
  [PackReviewStates.Draft]: {
    icon: <DraftIcon />,
    color: getStatusColor(PackReviewStates.Draft),
  },
};

const getStatusIcon = (state: PackReviewStates) => {
  const { spacing } = useTheme();
  const config = statusConfig[state];

  return React.cloneElement(config.icon, {
    sx: { color: config.color, fontSize: spacing(2.5) },
  });
};

const PackStatus: React.FC<PackStatusProps> = ({ state }) => {
  const { spacing } = useTheme();
  return (
    <DSBox
      sx={{
        gap: spacing(1),
        display: 'flex',
      }}
    >
      {getStatusIcon(state)}
      <DSTypography
        fontFamily={'Gilroy-SemiBold'}
        fontWeight={400}
        fontSize={spacing(2)}
        lineHeight={spacing(3)}
        color={getStatusColor(state)}
      >
        {state ? state.charAt(0).toUpperCase() + state.slice(1) : 'No Status'}
      </DSTypography>
    </DSBox>
  );
};

export default PackStatus;
