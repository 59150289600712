import { baseClient } from './baseRequest';

export type SocialMedia = {
  link: string;
  network: string;
};

export type User = {
  id: string;
  email: string;
  name: string;
  description: string;
  photoUrl: string;
  country: string;
  socialMedia: SocialMedia[];
};

export type SearchUserInput = {
  query: string;
  options: { limit: number; skip: number };
  searchFields: string[];
};

export type SearchUserOutput = {
  id: string;
  name: string;
};

export type UserApiClient = {
  login: (userId: string) => Promise<User | null>;
  signUp: (userId: string, user: Partial<User>) => Promise<User>;
  search: (input: SearchUserInput) => Promise<SearchUserOutput[]>;
  impersonate: (customUserId: string) => Promise<string>;
};

const userApiClient: UserApiClient = {
  login: (userId: string) => baseClient.get<User>({ url: `/users/${userId}` }),
  signUp: (userId: string, user: Partial<User>) =>
    baseClient.put<User>({ url: `/users/${userId}`, body: user }),
  search: (input: SearchUserInput) =>
    baseClient.post<SearchUserOutput[]>({
      url: '/admin/users/.search',
      body: input,
    }),
  impersonate: (customUserId: string) =>
    baseClient.post<string>({
      url: `/admin/users/impersonate/${customUserId}`,
    }),
};

export default userApiClient;
