import { useCallback, useState } from 'react';
import userApiClient, { SearchUserOutput } from '../../../api/user';
import firebaseService from '../../../firebase/firebaseService';
import useAppContextProvider from '../../../contexts/hooks/useAppContextProvider';
import useUsersQuery from './useUsersQuery';
import { useTheme } from '@mui/material';

const useUserDropdown = () => {
  const { spacing } = useTheme();
  const { setAuthUser } = useAppContextProvider();
  const [searchText, setSearchText] = useState('');
  const { data: users, isLoading } = useUsersQuery(searchText);

  const handleInputChange = useCallback(
    (event: React.SyntheticEvent, value: string) => {
      setSearchText(value);
    },
    [],
  );

  const handleOptionSelect = useCallback(
    async (event: React.SyntheticEvent, value: SearchUserOutput | null) => {
      if (value) {
        const token = await userApiClient.impersonate(value.id);
        const authUser = await firebaseService.impersonate(token);
        setAuthUser(authUser.user!);
      }
    },
    [setAuthUser],
  );

  const options = users?.pages.flatMap((page) => page) || [];

  return {
    options,
    handleInputChange,
    handleOptionSelect,
    isLoading,
    spacing,
  };
};

export default useUserDropdown;
