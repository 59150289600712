import React from 'react';
import { Chip, useTheme } from '@mui/material';

const DSSocialChip = (props: React.ComponentProps<typeof Chip>) => {
  const { spacing, palette } = useTheme();

  return (
    <Chip
      {...props}
      sx={{
        ...props.sx,
        gap: spacing(1),
        padding: spacing(1),
        borderRadius: 'var(--Corner-Full, 1000px)',
        fontFamily: 'Gilroy-SemiBold',
        fontSize: spacing(2),
        color: palette.text.secondary,
        borderColor: palette.secondary.dark,
        backgroundColor: palette.secondary.dark,
      }}
    />
  );
};

export default DSSocialChip;
