import { useState } from 'react';
import { KlydoUnion } from '../../../api/klydo';
import useKlydosView from '../../Klydos/KlydosList/hooks/useKlydosList';

export type UsePickKlydosModalProps = {
  onPick: (klydos: KlydoUnion[]) => void;
  onClose: () => void;
};
//TODO: decide what is the max number of klydos that can be selected for a pack
const MAX_KLYDOS = 10;

const usePickKlydosModal = ({ onPick, onClose }: UsePickKlydosModalProps) => {
  const [selectedKlydos, setSelectedKlydos] = useState<KlydoUnion[]>([]);
  const {
    data: klydos,
    isLoading,
    searchText,
    handleTextChange,
    fetchNextPage,
  } = useKlydosView();

  const toggleKlydoSelection = (klydo: KlydoUnion) => {
    setSelectedKlydos((prev) => {
      if (prev.some((selected) => selected.id === klydo.id)) {
        return prev.filter((selected) => selected.id !== klydo.id);
      } else if (prev.length < MAX_KLYDOS) {
        return [...prev, klydo];
      } else {
        alert(`You can select up to ${MAX_KLYDOS} Klydos.`);
        return prev;
      }
    });
  };

  const handleSave = () => {
    const uniqueKlydos = Array.from(new Set(selectedKlydos));
    onPick(uniqueKlydos);
    onClose();
  };

  return {
    klydos,
    isLoading,
    selectedKlydos,
    toggleKlydoSelection,
    handleSave,
  };
};

export default usePickKlydosModal;
