import React from 'react';
import firebase from 'firebase/compat/app';
import useUserInitializer from './hooks/useUserInitializer';
import SignupForm from '../views/SignUp/components/SignupForm';
import { SplashScreen } from '../SplashScreen/SplashScreen';
import { User } from '../api/user';

type UserInitializerProps = {
  children: React.ReactNode;
  authUser: firebase.User;
  setUser: (user: User) => void;
  isAdmin: boolean;
};

const UserInitializer: React.FC<UserInitializerProps> = ({
  children,
  authUser,
  setUser,
  isAdmin,
}) => {
  const { loading, user } = useUserInitializer(authUser, setUser, isAdmin);

  if (loading) {
    return <SplashScreen />;
  }
  if (!user) {
    return <SignupForm setUser={setUser} authUser={authUser} />;
  }
  return <>{children}</>;
};

export default UserInitializer;
