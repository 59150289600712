import { Theme } from '../../../../types';
import useKlydoColorStack from '../hooks/useKlydoColorStack';
import KlydoColorStackItem from './KlydoColorStackItem';
import DSIconButton from '../../../../design-system/DSIconButton';
import AddIcon from '@mui/icons-material/Add';
import DSBox from '../../../../design-system/DSBox';
import KlydoColorPickerModal from './KlydoColorPickerModal';
import DSModal from '../../../../design-system/DSModal';
import DSPaper from '../../../../design-system/Paper/DSPaper';

type KlydoColorStackProps = {
  themes?: Theme[];
  setTheme: (theme: Theme[]) => void;
  onChangeColor: (value: number) => void;
  isLoadingThemes: boolean;
};

const KlydoColorStack = ({
  themes,
  setTheme,
  onChangeColor,
}: KlydoColorStackProps) => {
  const {
    spacing,
    selectedIndex,
    handleColorClick,
    openColorPicker,
    setOpenColorPicker,
  } = useKlydoColorStack({
    onChangeColor,
  });

  return (
    <DSPaper
      sx={{
        backgroundColor: 'rgba(255, 255, 255, 0.09)',
        alignItems: 'center',
        display: 'flex',
        justifyContent: 'center',
        flexDirection: 'column',
        alignSelf: 'stretch',
        width: '100%',
        borderRadius: '28px',
        paddingX: spacing(7),
        paddingY: spacing(3),
        gap: spacing(2),
      }}
    >
      <DSBox
        sx={{
          display: 'flex',
          flexDirection: 'column',
          gap: spacing(1),
          height: spacing(33),
          overflowY: 'scroll',
        }}
      >
        {themes ? (
          themes.map((colorOption: Theme, index: number) => (
            <DSBox key={index} onClick={() => handleColorClick(index)}>
              <KlydoColorStackItem
                key={index}
                isSelected={selectedIndex === index}
                backgroundColor={colorOption.backgroundColor}
                handsColor={colorOption.handsColor}
                pendulumColor={colorOption.pendulumColor}
                pendulumRodColor={colorOption.pendulumRodColor}
                dialsColor={colorOption.dialsColor}
              />
            </DSBox>
          ))
        ) : (
          <>
            <KlydoColorStackItem />
            <KlydoColorStackItem />
            <KlydoColorStackItem />
          </>
        )}
      </DSBox>
      <DSIconButton
        onClick={() => setOpenColorPicker(true)}
        sx={{
          fontSize: '24px',
          width: '100%',
          height: '47px',
          padding: 'var(--2, 16px) var(--3, 24px)',
          borderRadius: 'var(--3, 24px)',
          border: '3px solid var(--action-active, rgba(255, 255, 255, 0.56))',
        }}
      >
        <AddIcon />
      </DSIconButton>
      <DSModal
        open={openColorPicker}
        onClose={() => setOpenColorPicker(false)}
        sx={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          backgroundColor: 'transparent',
        }}
        slotProps={{
          backdrop: {
            sx: {
              backgroundColor: 'transparent',
              boxShadow: 'rgba(0, 0, 0, 0.55) 0px 2px 3.2px 0px',
            },
          },
        }}
      >
        <KlydoColorPickerModal
          onClose={() => setOpenColorPicker(false)}
          onSave={(updatedTheme) => {
            setTheme([...(themes || []), updatedTheme]);
            setOpenColorPicker(false);
          }}
        />
      </DSModal>
    </DSPaper>
  );
};
export default KlydoColorStack;
