import { KLYDO_BLACK, KLYDO_WHITE, KLYDO_YELLOW } from '../../../consts/colors';
import DSAppBar from '../../../design-system/DSAppBar';
import DSAvatar from '../../../design-system/DSAvatar';
import DSBox from '../../../design-system/DSBox';
import DSToolbar from '../../../design-system/DSToolbar';
import { DSSubtitleTypography } from '../../../design-system/Typography';
import useAppBar from '../hooks/useAppBar';
import logo from '../../../images/klydo-clock-yellow-logo.png';
import { APPBAR_HIGHT } from '../../../consts/general';
import UserDropdown from './UserDropdown';
import DSChip from '../../../design-system/DSChip';

const AppBar = () => {
  const {
    user,
    isMobile,
    navigate,
    spacing,
    palette,
    zIndex,
    shouldShowUserDropdown,
    shouldShowImpersonatingChip,
  } = useAppBar();
  return (
    <DSAppBar
      position="fixed"
      elevation={0}
      sx={{
        height: spacing(APPBAR_HIGHT),
        backgroundColor: palette.background.default,
        p: spacing(1),
        zIndex: zIndex.appBar,
      }}
    >
      <DSToolbar
        sx={{
          justifyContent: 'space-between',
          backgroundColor: palette.background.default,
        }}
      >
        <DSBox
          sx={{
            display: 'flex',
            alignItems: 'center',
            marginLeft: { xs: spacing(5), md: 0 },
          }}
        >
          <img
            src={logo}
            alt="Logo"
            width={isMobile ? spacing(15) : spacing(22)}
            height={isMobile ? spacing(3) : spacing(5)}
          />
          {shouldShowUserDropdown && <UserDropdown />}
          {shouldShowImpersonatingChip && (
            <DSChip
              label={'Impersonating'}
              sx={{
                backgroundColor: KLYDO_YELLOW,
                color: KLYDO_BLACK,
                ml: spacing(2),
                paddingX: spacing(1),
                paddingY: spacing(2),
              }}
            />
          )}
        </DSBox>

        <DSBox sx={{ display: 'flex', alignItems: 'center', gap: spacing(2) }}>
          {!isMobile && (
            <DSSubtitleTypography color={KLYDO_WHITE} fontSize={spacing(2)}>
              {user?.name}
            </DSSubtitleTypography>
          )}
          <DSAvatar
            alt="Profile Avatar"
            src={user?.photoUrl}
            onClick={() => navigate('/profile')}
            sx={{
              width: { xs: spacing(5), md: spacing(6) },
              height: { xs: spacing(5), md: spacing(6) },
            }}
          />
        </DSBox>
      </DSToolbar>
    </DSAppBar>
  );
};
export default AppBar;
