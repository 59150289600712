import DSBox from '../../design-system/DSBox';
import usePendulum from './hooks/usePendulum';

const Pendulum = ({
  backgroundColor,
  pendulumColor,
  rodColor,
  isStatic,
}: {
  backgroundColor: string;
  pendulumColor: string;
  rodColor: string;
  isStatic?: boolean;
}) => {
  const { swingAnimation, rodSwingAnimation, zIndex } = usePendulum();

  return (
    <DSBox
      sx={{ alignItems: 'center', justifyContent: 'center', display: 'flex' }}
    >
      <DSBox
        sx={{
          position: 'absolute',
          top: '65%',
          height: '29%',
          width: '48%',
          borderRadius: '50%',
          backgroundColor: backgroundColor,
          overflow: 'hidden',
        }}
      >
        <DSBox
          sx={{
            backgroundColor: rodColor,
            bottom: '80%',
            width: '6%',
            height: '310%',
            position: 'absolute',
            alignItems: 'center',
            left: '49%',
            animation: !isStatic
              ? `${rodSwingAnimation} 2s ease-in-out infinite`
              : 'none',
            display: 'flex',
            zIndex: zIndex.pendulumRod,
          }}
        />
        <DSBox
          borderRadius="50%"
          boxShadow={'rgba(0, 0, 0, 0.55) 0px 2px 3.2px 0px'}
          sx={{
            position: 'absolute',
            bottom: '13%',
            right: '12%',
            width: '75%',
            height: '75%',
            backgroundColor: pendulumColor,
            transformOrigin: ' 50% 0px',
            animation: !isStatic
              ? `${swingAnimation} 2s ease-in-out infinite`
              : 'none',
            zIndex: zIndex.pendulumBall,
          }}
        />
      </DSBox>
    </DSBox>
  );
};

export default Pendulum;
