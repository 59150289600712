import { useState } from 'react';
import { PackReviewStates, PackType, PackUnion } from '../../../api/packs';
import { useNavigate } from 'react-router-dom';

const usePackItemMenuButton = (pack: PackUnion) => {
  const navigate = useNavigate();
  const [anchorElement, setAnchorElement] = useState<null | HTMLElement>(null);
  const handleMenuClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorElement(event.currentTarget);
  };
  const handleMenuClose = () => {
    setAnchorElement(null);
  };

  const handleEditPack = () => {
    navigate(`/edit-pack`, { state: { pack } });
  };

  const isButtonDisabled =
    pack.type === PackType.Draft &&
    (pack?.reviewStatus?.state === PackReviewStates.Published ||
      pack?.reviewStatus?.state === PackReviewStates.Pending);
  const isMenuOpen = Boolean(anchorElement);

  return {
    isButtonDisabled,
    anchorElement,
    handleMenuClick,
    handleMenuClose,
    handleEditPack,
    isMenuOpen,
  };
};

export default usePackItemMenuButton;
