import { useMutation, useQueryClient } from '@tanstack/react-query';
import klydoApiClient, { DraftKlydo } from '../../../api/klydo';

const useAddKlydoMutation = () => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationKey: ['klydosList'],
    mutationFn: (klydo: Partial<DraftKlydo>) =>
      klydoApiClient.createKlydo(klydo),
    onSuccess: () => {
      queryClient.refetchQueries({
        queryKey: ['klydosList'],
      });
    },
  });
};
export default useAddKlydoMutation;
