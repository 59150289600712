import React from 'react';
import { IconButton, IconButtonProps } from '@mui/material';

interface DSIconButtonProps extends IconButtonProps {
  component?: React.ElementType;
}
const DSIconButton = (props: DSIconButtonProps) => {
  return <IconButton {...props} />;
};

export default DSIconButton;
