import React from 'react';
import { Card, useTheme } from '@mui/material';

const DSKlydoCard = (props: React.ComponentProps<typeof Card>) => {
  const { spacing } = useTheme();
  return (
    <Card
      variant="elevation"
      elevation={5}
      {...props}
      sx={{
        ...props.sx,
        width: spacing(42),
        height: spacing(30),
        borderRadius: spacing(3.5),
        boxShadow:
          '0px 1px 14px 0px rgba(0, 0, 0, 0.12), 0px 5px 8px 0px rgba(0, 0, 0, 0.14), 0px 3px 5px -1px rgba(0, 0, 0, 0.20)',
        backgroundColor: ' #2A2A2A)',
        alignItems: 'center',
        justifyContent: 'center',
        display: 'flex',
      }}
    />
  );
};

export default DSKlydoCard;
