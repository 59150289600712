import React from 'react';
import { Dialog } from '@mui/material';

const DSDialog = (props: React.ComponentProps<typeof Dialog>) => {
  return (
    <Dialog
      {...props}
      sx={{
        ...props.sx,
        '.MuiDialog-paper': {
          borderRadius: 5,
        },
      }}
    />
  );
};

export default DSDialog;
