import { useMediaQuery, useTheme } from '@mui/material';
import useAppContext from '../../../hooks/useAppContext';
import { useNavigate } from 'react-router-dom';

const useAppBar = () => {
  const { user, isAdmin, isImpersonating } = useAppContext();

  const { spacing, breakpoints, palette, zIndex } = useTheme();
  const isMobile = useMediaQuery(breakpoints.down('md'));
  const navigate = useNavigate();

  const shouldShowUserDropdown = isAdmin && !isMobile;
  const shouldShowImpersonatingChip = isImpersonating && !isMobile;

  return {
    user,
    isAdmin,
    isImpersonating,
    isMobile,
    navigate,
    spacing,
    zIndex,
    palette,
    shouldShowUserDropdown,
    shouldShowImpersonatingChip,
  };
};
export default useAppBar;
