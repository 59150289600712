import { useQueryClient } from '@tanstack/react-query';
import useKlydoMutation, {
  UseKlydoMutationOptions,
} from '../../../hooks/useKlydoMutation';
import userApiClient, { User } from '../../../api/user';

const useUserMutation = (
  userId: string,
  options: Omit<
    UseKlydoMutationOptions<
      User,
      Partial<User>,
      { previousUser: User | undefined }
    >,
    'onSuccess' | 'onSettled'
  > & {
    onSuccess?: (user: User) => void;
    onSettled?: (user: User | undefined, error: unknown) => void;
    optimistic?: boolean;
  } = {},
) => {
  const queryClient = useQueryClient();

  return useKlydoMutation<
    User,
    Partial<User>,
    { previousUser: User | undefined }
  >({
    mutationFn: (user: Partial<User>) => userApiClient.signUp(userId, user),
    mutationKey: ['user', userId],
    onMutate: async (user) => {
      if (options.optimistic) {
        const queryKeys = ['user', userId];
        await queryClient.cancelQueries({ queryKey: queryKeys });

        const previousUser: User | undefined =
          queryClient.getQueryData(queryKeys);

        queryClient.setQueryData(queryKeys, (oldUser: User) => ({
          ...oldUser,
          ...user,
        }));

        return { previousUser };
      }
      options.onMutate?.(user);
      return { previousUser: undefined };
    },
    onError: (error, user, context) => {
      if (options.optimistic && context) {
        queryClient.setQueryData(['user', userId], context.previousUser);
      }
      options.onError?.(error, user, context);
    },
    onSettled: async (user, error) => {
      const queryKeys = ['user', userId];

      if (options.optimistic) {
        await queryClient.invalidateQueries({ queryKey: queryKeys });
      }

      options.onSettled?.(user, error);
    },
    ...options,
  });
};

export default useUserMutation;
