import useProfileItemMenuButton from '../hooks/useProfileItemMenuButton';
import DSBox from '../../../design-system/DSBox';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import DSMenuItem from '../../../design-system/DSMenuItem';
import DSMenu from '../../../design-system/DSMenu';
import { useTheme } from '@mui/material';
import DSMenuIconButton from '../../../design-system/DSMenuIconButton';

type ProfileItemMenuButtonProps = {
  onEdit: () => void;
  onLogout: () => void;
};
const ProfileItemMenuButton = ({
  onEdit,
  onLogout,
}: ProfileItemMenuButtonProps) => {
  const { palette } = useTheme();
  const { anchorElement, isMenuOpen, handleMenuClick, handleMenuClose } =
    useProfileItemMenuButton();
  return (
    <DSBox>
      <DSMenuIconButton
        onClick={handleMenuClick}
        aria-controls={isMenuOpen ? 'basic-menu' : undefined}
        aria-haspopup="true"
        aria-expanded={isMenuOpen ? 'true' : undefined}
      >
        <MoreVertIcon
          sx={{
            color: palette.action.active,
          }}
        />
      </DSMenuIconButton>
      <DSMenu
        anchorEl={anchorElement}
        open={isMenuOpen}
        onClose={handleMenuClose}
        sx={{
          mt: 1,
        }}
      >
        <DSMenuItem onClick={() => onEdit()}>Edit Profile</DSMenuItem>
        <DSMenuItem onClick={() => onLogout()}>Log Out</DSMenuItem>
      </DSMenu>
    </DSBox>
  );
};

export default ProfileItemMenuButton;
