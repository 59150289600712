import { useQueryClient } from '@tanstack/react-query';
import klydoApiClient from '../../../api/klydo';
import useKlydoMutation from '../../../hooks/useKlydoMutation';

const useRemoveKlydoMutation = () => {
  const queryClient = useQueryClient();

  return useKlydoMutation({
    mutationKey: ['removeKlydos'],
    mutationFn: (klydoId: string) => {
      return klydoApiClient.deleteKlydo(klydoId);
    },
    onSuccess: (_, klydoId) => {
      queryClient.refetchQueries({ queryKey: ['klydosList'] });

      queryClient.invalidateQueries({ queryKey: ['klydo', klydoId] });
    },
  });
};

export default useRemoveKlydoMutation;
