import studioApiClient from '../../../api/studio';
import useKlydoQuery from '../../../hooks/useKlydoQuery';

export const useSumDise = () => {
  return useKlydoQuery<number>({
    queryKey: ['SumDise'],
    queryFn: () => studioApiClient.getSumeDise(),
    refetchOnWindowFocus: false,
    keepPreviousData: true,
  });
};
