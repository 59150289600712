import { useTheme } from '@mui/material';
import { useCallback } from 'react';

type UseKlydoFileInputProps = {
  onFileChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
  onRemoveFile: () => void;
};
const useKlydoFileInput = ({
  onFileChange,
  onRemoveFile,
}: UseKlydoFileInputProps) => {
  const { spacing, palette } = useTheme();

  const handleFileChange = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      onFileChange(event);
    },
    [onFileChange],
  );

  const handleRemoveFile = useCallback(() => {
    onRemoveFile();
  }, [onRemoveFile]);

  return { spacing, handleFileChange, palette, handleRemoveFile };
};
export default useKlydoFileInput;
