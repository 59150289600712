import { ApprovedKlydo, KlydoType, KlydoUnion } from '../../../../api/klydo';
import KlydoPreview from '../../../../components/klydoPreview/KlydoPreview';
import { DSCardActions, DSCardContent } from '../../../../design-system/Card';
import DSBox from '../../../../design-system/DSBox';
import DSKlydoCard from '../../../../design-system/Klydo/DSKlydoCard';
import {
  DSTitleTypography,
  DSSubtitleTypography,
} from '../../../../design-system/Typography';
import useKlydoCard from '../hooks/useKlydoCard';
import KlydoItemMenuButton from './KlydoItemMenuButton';
import KlydoStatus from './KlydoStatus';
import DSDivider from '../../../../design-system/DSDivider';
import { KLYDO_YELLOW } from '../../../../consts/colors';

export type KlydoCardProps = {
  klydo: KlydoUnion;
  isStatic?: boolean;
  isMenuHidden?: boolean;
  isSelected?: boolean;
  onSelect?: (klydo: ApprovedKlydo) => void;
};

const KlydoCard = ({
  klydo,
  isStatic,
  isMenuHidden,
  onSelect,
  isSelected,
}: KlydoCardProps) => {
  const {
    deleteKlydoMutation,
    handleSubmitKlydo,
    theme,
    klydoNameLengthLimit,
  } = useKlydoCard();

  return (
    <DSKlydoCard
      onClick={() => onSelect && onSelect(klydo as ApprovedKlydo)}
      sx={{ border: isSelected ? `2px solid ${KLYDO_YELLOW}` : 'none' }}
    >
      <DSBox
        sx={{
          width: theme.spacing(16),
          height: theme.spacing(25),
          position: 'relative',
        }}
      >
        <KlydoPreview
          klydo={klydo}
          isAnimationStatic={isStatic}
          isPendulumStatic={isStatic}
        />
      </DSBox>
      <DSBox
        sx={{
          display: 'flex',
          flexDirection: 'column',
        }}
      >
        <DSCardContent sx={{ textAlign: 'left', paddingBottom: 0 }}>
          <DSBox height={theme.spacing(14)} width={theme.spacing(20)}>
            <DSTitleTypography
              sx={{
                whiteSpace: 'normal',
                wordBreak: 'break-word',
                overflowWrap: 'break-word',
              }}
            >
              {klydo.name.length > klydoNameLengthLimit
                ? `${klydo.name.substring(0, klydoNameLengthLimit)}...`
                : klydo.name}
            </DSTitleTypography>

            <DSSubtitleTypography>
              Updated At{' '}
              {new Date(klydo.updatedAt)
                .toLocaleDateString(undefined, {
                  year: '2-digit',
                  month: '2-digit',
                  day: '2-digit',
                })
                .replace(/\//g, '.')}
            </DSSubtitleTypography>
          </DSBox>
        </DSCardContent>
        <DSDivider
          flexItem
          sx={{
            alignSelf: 'center',
            width: '90%',
            borderWidth: '2px',
          }}
        />
        <DSBox>
          <DSCardActions sx={{ justifyContent: 'space-between' }}>
            <KlydoStatus
              state={
                klydo.type === KlydoType.Approved
                  ? KlydoType.Approved
                  : klydo?.reviewStatus?.state
              }
            />
            {isMenuHidden ? null : (
              <KlydoItemMenuButton
                klydo={klydo}
                onDelete={deleteKlydoMutation}
                onSubmit={handleSubmitKlydo}
              />
            )}
          </DSCardActions>
        </DSBox>
      </DSBox>
    </DSKlydoCard>
  );
};

export default KlydoCard;
