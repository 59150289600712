import React from 'react';
import { Menu, useTheme } from '@mui/material';

const DSMenu = (props: React.ComponentProps<typeof Menu>) => {
  const { spacing } = useTheme();
  return (
    <Menu
      {...props}
      MenuListProps={{
        'aria-labelledby': 'basic-button',
      }}
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'right',
      }}
      transformOrigin={{
        vertical: 'top',
        horizontal: 'right',
      }}
      sx={{
        ...props.sx,
        '& .MuiPaper-root': {
          borderRadius: spacing(2),
          minWidth: spacing(20),
        },
      }}
    />
  );
};

export default DSMenu;
