import DSGrid from '../../../design-system/DSGrid';
import PackCard from './PackCard';
import usePacksList from '../hooks/usePacksList';
import { DSSubtitleTypography } from '../../../design-system/Typography';
import { useTheme } from '@mui/material';
import DSBox from '../../../design-system/DSBox';
import { useNavigate } from 'react-router-dom';
import loading from '../../../images/loading.png';

const PacksGrid = () => {
  const { data: packs, isLoading } = usePacksList();
  const { spacing } = useTheme();
  const navigate = useNavigate();

  if (!packs || packs.length === 0 || isLoading) {
    return (
      <DSBox
        display="flex"
        flexDirection="column"
        justifyContent="center"
        alignItems="center"
        height="70vh"
      >
        <img
          src={loading}
          alt="Logo"
          width={spacing(26)}
          height={spacing(27)}
        />
        {isLoading ? (
          <DSSubtitleTypography fontSize={spacing(2.5)}>
            Loading...
          </DSSubtitleTypography>
        ) : (
          <>
            <DSSubtitleTypography fontSize={spacing(2.5)}>
              You don't have any design yet
            </DSSubtitleTypography>

            <DSSubtitleTypography
              onClick={() => navigate('/edit-pack')}
              fontSize={spacing(2.5)}
              sx={{
                textDecorationLine: 'underline',
                cursor: 'pointer',
              }}
            >
              Click to create a new Pack
            </DSSubtitleTypography>
          </>
        )}
      </DSBox>
    );
  }

  return (
    <DSGrid
      container
      rowGap={{
        xs: spacing(3),
        md: spacing(5),
        lg: spacing(8),
        xl: spacing(5),
      }}
      spacing={{
        xs: spacing(3),
        md: spacing(5),
        lg: spacing(8),
        xl: spacing(5),
      }}
      columns={{ xs: 1 }}
      sx={{
        display: 'grid',
        gridTemplateColumns: {
          xs: '1fr',
          sm: '1fr',
          md: 'repeat(3, 1fr)',
          lg: 'repeat(3, 1fr)',
          xl: 'repeat(5, 1fr)',
        },
        padding: spacing(2),
      }}
    >
      {packs.map((pack) => (
        <DSGrid key={pack.id}>
          <PackCard pack={pack} />
        </DSGrid>
      ))}
    </DSGrid>
  );
};

export default PacksGrid;
