import React from 'react';
import { KlydoReviewStates, KlydoType } from '../../../../api/klydo';
import DSBox from '../../../../design-system/DSBox';
import { DSTypography } from '../../../../design-system/Typography';
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import {
  DRAFT,
  PENDING,
  PUBLISHED_APPROVED,
  REJECTED,
} from '../../../../consts/colors';
import HighlightOffIcon from '@mui/icons-material/HighlightOff';
import QueryBuilderOutlinedIcon from '@mui/icons-material/QueryBuilderOutlined';
import { ReactComponent as DraftIcon } from '../../../../design-system/Icons/DraftIcon.svg';
import { useTheme } from '@mui/material';

type KlydoStatusProps = {
  state: KlydoType.Approved | KlydoReviewStates;
};

const getStatusColor = (state: KlydoType.Approved | KlydoReviewStates) => {
  switch (state) {
    case KlydoType.Approved:
      return PUBLISHED_APPROVED;
    case KlydoReviewStates.Pending:
      return PENDING;
    case KlydoReviewStates.Rejected:
      return REJECTED;
    case KlydoReviewStates.Draft:
      return DRAFT;
    default:
      return 'default';
  }
};

const statusConfig = {
  [KlydoType.Approved]: {
    icon: <CheckCircleOutlineIcon />,
    color: getStatusColor(KlydoType.Approved),
  },
  [KlydoReviewStates.Pending]: {
    icon: <QueryBuilderOutlinedIcon />,
    color: getStatusColor(KlydoReviewStates.Pending),
  },
  [KlydoReviewStates.Rejected]: {
    icon: <HighlightOffIcon />,
    color: getStatusColor(KlydoReviewStates.Rejected),
  },
  [KlydoReviewStates.Draft]: {
    icon: <DraftIcon />,
    color: getStatusColor(KlydoReviewStates.Draft),
  },
};

const getStatusIcon = (
  state: typeof KlydoType.Approved | KlydoReviewStates,
) => {
  const { spacing } = useTheme();
  const config = statusConfig[state] || statusConfig[KlydoReviewStates.Draft];

  return React.cloneElement(config.icon, {
    sx: { color: config.color, fontSize: spacing(2.5) },
  });
};

const getStatusText = (state: KlydoType.Approved | KlydoReviewStates) => {
  if (state === KlydoType.Approved) {
    return 'Approved';
  }

  return state?.charAt(0).toUpperCase() + state?.slice(1);
};

const KlydoStatus: React.FC<KlydoStatusProps> = ({ state }) => {
  const { spacing } = useTheme();

  return (
    <DSBox sx={{ gap: spacing(1), display: 'flex' }}>
      {getStatusIcon(state)}
      <DSTypography
        fontFamily={'Gilroy-SemiBold'}
        fontWeight={400}
        fontSize={spacing(2)}
        lineHeight={spacing(3)}
        color={getStatusColor(state)}
      >
        {getStatusText(state)}
      </DSTypography>
    </DSBox>
  );
};

export default KlydoStatus;
