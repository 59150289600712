import React from 'react';
import { ReactComponent as FacebookIcon } from '../../../design-system/Icons/Facebook.svg';
import { ReactComponent as InstagramIcon } from '../../../design-system/Icons/Instagram.svg';
import { ReactComponent as GlobalIcon } from '../../../design-system/Icons/Global.svg';
import { ReactComponent as LinkedinIcon } from '../../../design-system/Icons/Linkedin.svg';
import { SocialMedia } from '../../../api/user';
import DSStack from '../../../design-system/DSStack';
import { useTheme } from '@mui/material';
import DSSocialChip from '../../../design-system/DSSocialChip';

export enum SocialNetwork {
  Instagram = 'instagram',
  LinkedIn = 'linkedin',
  Facebook = 'facebook',
  Default = 'default',
}

type SocialChipStackProps = {
  socialMedia: SocialMedia[];
};

const SocialChipStack: React.FC<SocialChipStackProps> = ({ socialMedia }) => {
  const { palette } = useTheme();
  const iconMap: Record<string, React.ElementType> = {
    [SocialNetwork.Instagram]: InstagramIcon,
    [SocialNetwork.LinkedIn]: LinkedinIcon,
    [SocialNetwork.Facebook]: FacebookIcon,
    [SocialNetwork.Default]: GlobalIcon,
  };

  return (
    <DSStack direction="row" spacing={2}>
      {socialMedia.map((item, index) => {
        const IconComponent = iconMap[item.network.toLowerCase()];
        return (
          <DSSocialChip
            key={index}
            icon={
              <IconComponent
                color={palette.text.secondary}
                width={'20px'}
                height={'20px'}
              />
            }
            label={item.link}
          />
        );
      })}
    </DSStack>
  );
};

export default SocialChipStack;
