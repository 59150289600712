import React, { forwardRef } from 'react';
import { Modal, Box } from '@mui/material';

const DSModal = forwardRef<HTMLDivElement, React.ComponentProps<typeof Modal>>(
  (props, ref) => {
    const { children, ...modalProps } = props;

    return (
      <Modal {...modalProps}>
        <Box ref={ref} sx={{ outline: 'none' }}>
          {children}
        </Box>
      </Modal>
    );
  },
);

export default DSModal;
