import React from 'react';
import { Divider } from '@mui/material';

const DSDivider = (props: React.ComponentProps<typeof Divider>) => {
  return (
    <Divider
      {...props}
      sx={{
        ...props.sx,
        color: 'rgba(255, 255, 255, 0.12)',
      }}
    />
  );
};

export default DSDivider;
