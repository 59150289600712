import {
  InfiniteData,
  useMutation,
  useQueryClient,
} from '@tanstack/react-query';
import klydoApiClient, { DraftKlydo } from '../../../api/klydo';

const useUpdateKlydoMutation = () => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationKey: ['update-klydo'],
    mutationFn: ({
      klydoId,
      klydo,
    }: {
      klydoId: string;
      klydo: Partial<DraftKlydo>;
    }) => {
      return klydoApiClient.updateKlydo(klydoId, klydo);
    },
    onMutate: ({
      klydoId,
      klydo,
    }: {
      klydoId: string;
      klydo: Partial<DraftKlydo>;
    }) => {
      const matchingQueries = queryClient
        .getQueryCache()
        .findAll({ queryKey: ['klydosList'] });

      matchingQueries.forEach(({ queryKey }) => {
        queryClient.setQueryData<InfiniteData<DraftKlydo[]>>(
          queryKey,
          (oldData) => {
            if (!oldData) return oldData;

            return {
              ...oldData,
              pages: oldData.pages.map((page) =>
                page.map((oldklydo) =>
                  oldklydo.id === klydoId
                    ? { ...oldklydo, ...klydo }
                    : oldklydo,
                ),
              ),
            };
          },
        );
      });
    },
    onSettled: () =>
      queryClient.refetchQueries({
        queryKey: ['klydosList'],
      }),
  });
};

export default useUpdateKlydoMutation;
