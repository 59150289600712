import DSBox from '../../../design-system/DSBox';
import DSGrid from '../../../design-system/DSGrid';
import DSDialog from '../../../design-system/DSDialog';
import { DSTypography } from '../../../design-system/Typography';
import DSDialogActions from '../../../design-system/DSDialogActions';
import DSPrimaryButton from '../../../design-system/DSPrimaryButtom';
import DSSecondaryButton from '../../../design-system/DSSecondaryButton';
import usePickKlydosDialog from '../hooks/usePickKlydosDialog';
import { KlydoUnion } from '../../../api/klydo';
import DSButton from '../../../design-system/DSButtom';
import KlydoCard from '../../Klydos/KlydosList/components/KlydoCard';
import DSHeaderTypography from '../../../design-system/Typography/DSHeaderTypography';
import DSValueTypography from '../../../design-system/Typography/DSValueTypography';

interface PickKlydosDialogProps {
  isOpen: boolean;
  onClose: () => void;
  onPick: (selectedKlydos: KlydoUnion[]) => void;
}

const PickKlydosDialog = ({
  isOpen,
  onClose,
  onPick,
}: PickKlydosDialogProps) => {
  const {
    klydos,
    isLoading,
    selectedKlydos,
    handleSave,
    toggleKlydoSelection,
    fetchNextPage,
    isFetchingNextPage,
    hasNextPage,
    spacing,
  } = usePickKlydosDialog({ onPick, onClose });

  return (
    <DSDialog open={isOpen} maxWidth="xl" onClose={onClose} title="Pick Klydos">
      <DSBox
        sx={{
          padding: 3,
          display: 'flex',
          flexDirection: 'column',
          gap: 2,
        }}
      >
        <DSBox>
          <DSHeaderTypography>Add a Klydo</DSHeaderTypography>
          <DSValueTypography>
            Click to choose the Klydo you want to add to your pack
          </DSValueTypography>
        </DSBox>
        {isLoading ? (
          <DSTypography variant="body2" sx={{ textAlign: 'center' }}>
            Loading Klydos...
          </DSTypography>
        ) : klydos && klydos.length > 0 ? (
          <DSBox
            sx={{
              width: '100%',
              maxHeight: '400px',
              overflowY: 'auto',
            }}
          >
            <DSGrid
              container
              rowGap={{
                xs: spacing(3),
                sm: spacing(3),
                md: spacing(5),
                lg: spacing(8),
                xl: spacing(5),
              }}
              spacing={{
                xs: spacing(3),
                sm: spacing(3),
                md: spacing(5),
                lg: spacing(8),
                xl: spacing(5),
              }}
              columns={{ xs: 1, sm: 1 }}
              sx={{
                display: 'grid',
                gridTemplateColumns: {
                  xs: '1fr',
                  sm: '1fr',
                  md: 'repeat(2, 1fr)',
                  lg: 'repeat(2, 1fr)',
                  xl: 'repeat(2, 1fr)',
                },
                padding: { sm: spacing(2), md: spacing(4) },
              }}
            >
              {klydos.map((klydo) => (
                <DSGrid key={klydo.id}>
                  <KlydoCard
                    key={klydo.id}
                    klydo={{ ...klydo }}
                    isStatic
                    isMenuHidden
                    isSelected={selectedKlydos.some(
                      (selected) => selected.id === klydo.id,
                    )}
                    onSelect={toggleKlydoSelection}
                  />
                </DSGrid>
              ))}
              {hasNextPage && (
                <DSButton
                  disabled={isFetchingNextPage}
                  onClick={() => fetchNextPage()}
                >
                  {isFetchingNextPage ? 'Loading...' : 'Load More'}
                </DSButton>
              )}
            </DSGrid>
          </DSBox>
        ) : (
          <DSTypography variant="body2" sx={{ textAlign: 'center' }}>
            No Klydos available.
          </DSTypography>
        )}
      </DSBox>
      <DSDialogActions sx={{ justifyContent: 'space-between', padding: 3 }}>
        <DSSecondaryButton onClick={onClose}>Cancel</DSSecondaryButton>
        <DSPrimaryButton
          variant="contained"
          color="primary"
          onClick={handleSave}
          disabled={selectedKlydos.length === 0}
        >
          Save
        </DSPrimaryButton>
      </DSDialogActions>
    </DSDialog>
  );
};

export default PickKlydosDialog;
