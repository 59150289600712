import { useTheme } from '@mui/material';
import DSGrid from '../../../../design-system/DSGrid';
import { DSSubtitleTypography } from '../../../../design-system/Typography';
import DSButton from '../../../../design-system/DSButtom';
import { KlydoUnion } from '../../../../api/klydo';
import KlydoCard from './KlydoCard';
import DSBox from '../../../../design-system/DSBox';
import loading from '../../../../images/loading.png';
import { useNavigate } from 'react-router-dom';

export type KlydoGridProps = {
  isLoading: boolean;
  klydos: KlydoUnion[] | undefined;
  fetchNextPage: () => void;
  hasNextPage: boolean;
  isFetchingNextPage: boolean;
};

const KlydoGrid = ({
  isLoading,
  klydos,
  fetchNextPage,
  hasNextPage,
  isFetchingNextPage,
}: KlydoGridProps) => {
  const { spacing } = useTheme();
  const navigate = useNavigate();

  if (!klydos || klydos.length === 0 || isLoading) {
    return (
      <DSBox
        display="flex"
        flexDirection="column"
        justifyContent="center"
        alignItems="center"
        height="70vh"
      >
        <img
          src={loading}
          alt="Logo"
          width={spacing(26)}
          height={spacing(27)}
        />
        {isLoading ? (
          <DSSubtitleTypography fontSize={spacing(2.5)}>
            Loading...
          </DSSubtitleTypography>
        ) : (
          <>
            <DSSubtitleTypography fontSize={spacing(2.5)}>
              You don't have any design yet
            </DSSubtitleTypography>

            <DSSubtitleTypography
              onClick={() => navigate('/create-klydo')}
              fontSize={spacing(2.5)}
              sx={{
                textDecorationLine: 'underline',
                cursor: 'pointer',
              }}
            >
              Click to create a new Klydo
            </DSSubtitleTypography>
          </>
        )}
      </DSBox>
    );
  }

  return (
    <>
      <DSGrid
        container
        rowGap={{
          xs: spacing(3),
          sm: spacing(3),
          md: spacing(5),
          lg: spacing(8),
          xl: spacing(5),
        }}
        spacing={{
          xs: spacing(3),
          sm: spacing(3),
          md: spacing(5),
          lg: spacing(8),
          xl: spacing(5),
        }}
        columns={{ xs: 1, sm: 1 }}
        sx={{
          display: 'grid',
          gridTemplateColumns: {
            xs: '1fr',
            sm: '1fr',
            md: 'repeat(3, 1fr)',
            lg: 'repeat(3, 1fr)',
            xl: 'repeat(4, 1fr)',
          },
          padding: { sm: spacing(2), md: spacing(4) },
        }}
      >
        {klydos.map((klydo) => (
          <DSGrid key={klydo.id}>
            <KlydoCard klydo={{ ...klydo }} isStatic />
          </DSGrid>
        ))}
        {hasNextPage && (
          <DSButton
            disabled={isFetchingNextPage}
            onClick={() => fetchNextPage()}
          >
            {isFetchingNextPage ? 'Loading...' : 'Load More'}
          </DSButton>
        )}
      </DSGrid>
    </>
  );
};

export default KlydoGrid;
