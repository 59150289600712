import DSBox from '../../../design-system/DSBox';
import KlydoMap from '../../KlydoMap/components/KlydoMap';
import { useDashboard } from '../hooks/useDashboard';
import DSTabHeaderTypography from '../../../design-system/Typography/DSSTabHeaderTypography';
import DSHeaderTypography from '../../../design-system/Typography/DSHeaderTypography';
import KlydoCard from '../../Klydos/KlydosList/components/KlydoCard';
import DSGrid from '../../../design-system/DSGrid';
import VisibilityOutlinedIcon from '@mui/icons-material/VisibilityOutlined';
import DSGradientViewPaper from '../../../design-system/Paper/DSGradientViewPaper';

const Dashboard = () => {
  const { spacing, topKlydos, sumDise } = useDashboard();

  return (
    <DSBox
      sx={{
        display: 'flex',
        flexDirection: 'column',
        paddingX: { sm: spacing(2), md: spacing(5) },
        overflow: 'hidden',
        height: { sm: 'auto', md: '90vh' },
      }}
    >
      <DSBox sx={{ paddingTop: spacing(4), paddingBottom: spacing(8) }}>
        <DSTabHeaderTypography>Dashboard</DSTabHeaderTypography>
      </DSBox>
      <DSBox
        sx={{
          flexGrow: 1,
          display: 'grid',
          gridTemplateAreas: {
            xs: `
              "dise"
              "top5"
              "map"
            `,
            md: `
              "dise top5"
              "map top5"
            `,
          },
          gridTemplateColumns: { xs: '1fr', md: '2fr 1fr' },
          gridTemplateRows: { xs: 'auto auto auto 1fr', md: '0fr auto 0fr' },
          gap: 6,
        }}
      >
        <DSGradientViewPaper
          sx={{
            gridArea: 'top5',
            flexDirection: 'column',
            width: { lg: spacing(53) },
          }}
        >
          <DSHeaderTypography>Top Klydos</DSHeaderTypography>
          <DSBox
            sx={{
              height: '95%',
              overflow: 'auto',
            }}
          >
            <DSGrid
              container
              rowGap={spacing(2)}
              spacing={spacing(2)}
              columns={{ xs: 1 }}
              overflow={'hidden'}
              sx={{
                display: 'grid',
                gridTemplateColumns: '1fr',
                paddingY: spacing(3),
              }}
            >
              {topKlydos?.map((klydo, index) => (
                <KlydoCard key={index} klydo={klydo} isStatic isMenuHidden />
              ))}
            </DSGrid>
          </DSBox>
        </DSGradientViewPaper>
        <DSGradientViewPaper
          sx={{
            gridArea: 'dise',
            flexDirection: 'row',
            justifyContent: 'space-between',
            alignItems: 'center',
          }}
        >
          <DSHeaderTypography>DiSe</DSHeaderTypography>
          <DSHeaderTypography
            sx={{ display: 'flex', alignItems: 'center', gap: 1 }}
          >
            <VisibilityOutlinedIcon />
            {sumDise?.toLocaleString() || '0'}
          </DSHeaderTypography>
        </DSGradientViewPaper>
        <DSGradientViewPaper
          sx={{
            gridArea: 'map',
            flexDirection: 'column',
            height: { sm: '100vh', md: 'auto' },
          }}
        >
          <DSHeaderTypography sx={{ marginBottom: spacing(4) }}>
            Your Klydo Map
          </DSHeaderTypography>
          <KlydoMap />
        </DSGradientViewPaper>
      </DSBox>
    </DSBox>
  );
};

export default Dashboard;
