import { useNavigate } from 'react-router-dom';
import { useKlydosTopFive } from './useKlydosTopFive';
import { useTheme } from '@mui/material';
import { useSumDise } from './useSumDise';

export const useDashboard = () => {
  const navigate = useNavigate();
  const { spacing } = useTheme();
  const { data: topKlydos, isLoading, isError } = useKlydosTopFive();
  const { data: sumDise } = useSumDise();

  return {
    topKlydos,
    sumDise,
    navigate,
    isLoading,
    isError,
    spacing,
  };
};
