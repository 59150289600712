import { useContext, useState } from 'react';
import { AppContext } from '../../../contexts/AppContext';
import useUserMutation from '../../SignUp/hooks/useUserMutation';
import { useNavigate } from 'react-router-dom';
import { SocialMedia, User } from '../../../api/user';

const useProfileEditable = () => {
  const { user, setUser } = useContext(AppContext);
  const navigate = useNavigate();

  const [name, setName] = useState(user?.name || '');
  const [description, setDescription] = useState(user?.description || '');
  const [profileImage, setProfileImage] = useState(user?.photoUrl);
  const [country, setCountry] = useState(user?.country || '');
  const [socialMedia, setSocialMedia] = useState<SocialMedia[]>(
    user?.socialMedia || [{ link: '', network: '' }],
  );
  const [email] = useState(user?.email || '');

  const { mutate } = useUserMutation(user.id, {
    onSuccess: (user: User) => {
      setUser(user);
    },
    optimistic: false,
  });

  const handleAddSocialMedia = () => {
    setSocialMedia([...socialMedia, { link: '', network: '' }]);
  };

  const handleRemoveSocialMedia = (index: number) => {
    setSocialMedia(socialMedia.filter((_, i) => i !== index));
  };

  const handleSave = () => {
    const updatedData: Partial<User> = {
      name,
      description,
      photoUrl: profileImage,
      country,
      socialMedia,
    };

    setUser({ ...user!, ...updatedData });
    mutate({ ...user!, ...updatedData });
    navigate('/profile');
  };

  return {
    name,
    setName,
    description,
    setDescription,
    profileImage,
    setProfileImage,
    country,
    setCountry,
    socialMedia,
    setSocialMedia,
    email,
    handleAddSocialMedia,
    handleRemoveSocialMedia,
    handleSave,
  };
};

export default useProfileEditable;
