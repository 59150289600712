const firebaseConfig =
  !process.env.NODE_ENV || process.env.NODE_ENV === 'development'
    ? {
        apiKey: 'AIzaSyDgSZk_0y-JMS6-EsfukWlP2__1Yq4fz54',
        authDomain: 'klydo-development.firebaseapp.com',
        projectId: 'klydo-development',
        storageBucket: 'klydo-development.appspot.com',
        messagingSenderId: '238468556119',
        appId: '1:238468556119:web:fad044c3d5e7903004ca12',
        measurementId: 'G-F8D09R6R4V',
      }
    : {
        apiKey: 'AIzaSyCnNaft6lR7g9RWBeofSQVRiefqrPZWimg',
        authDomain: 'dazzling-mantra-347515.firebaseapp.com',
        projectId: 'dazzling-mantra-347515',
        storageBucket: 'dazzling-mantra-347515.appspot.com',
        messagingSenderId: '917257785826',
        appId: '1:917257785826:web:4dcd5036984c3f8448a9bb',
        measurementId: 'G-L4DBBBVNFT',
      };

export default firebaseConfig;
