import React, { forwardRef } from 'react';
import { Button, useTheme } from '@mui/material';

const DSPrimaryButton = forwardRef<
  HTMLButtonElement,
  React.ComponentProps<typeof Button>
>((props, ref) => {
  const { spacing } = useTheme();
  return (
    <Button
      ref={ref}
      color={'primary'}
      variant="contained"
      {...props}
      sx={{
        ...props.sx,
        borderRadius: 'var(--Corner-Full, 1000px)',
        boxShadow:
          '0px 1px 5px 0px rgba(0, 0, 0, 0.12), 0px 2px 2px 0px rgba(0, 0, 0, 0.14), 0px 3px 1px -2px rgba(0, 0, 0, 0.20)',
        fontFamily: 'Gilroy-SemiBold',
        fontSize: spacing(2),
      }}
    />
  );
});

export default DSPrimaryButton;
