import { useTheme } from '@mui/material';
import useRemoveKlydoMutation from '../../hooks/useRemoveKlydoMutation';
import useUpdateKlydoMutation from '../../hooks/useUpdateKlydoMutation';
import { DraftKlydo, KlydoReviewStates } from '../../../../api/klydo';

const useKlydoCard = () => {
  const klydoNameLengthLimit = 34;
  const theme = useTheme();

  const { mutate: deleteKlydoMutation } = useRemoveKlydoMutation();
  const { mutate: updateKlydoMutation } = useUpdateKlydoMutation();

  const handleSubmitKlydo = (klydo: DraftKlydo) => {
    updateKlydoMutation({
      klydoId: klydo.id,
      klydo: {
        ...klydo,
        reviewStatus: {
          state: KlydoReviewStates.Pending,
          message: '',
        },
      },
    });
  };

  return {
    deleteKlydoMutation,
    handleSubmitKlydo,
    theme,
    klydoNameLengthLimit,
  };
};

export default useKlydoCard;
