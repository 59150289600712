import DSAvatar from '../../../design-system/DSAvatar';
import DSIconButton from '../../../design-system/DSIconButton';
import { DSTypography } from '../../../design-system/Typography';
import DSBox from '../../../design-system/DSBox';
import { KLYDO_BLACK, KLYDO_WHITE } from '../../../consts/colors';
import DSTextField from '../../../design-system/DSTextField';
import AddIcon from '@mui/icons-material/Add';
import ClearIcon from '@mui/icons-material/Clear';
import DSButton from '../../../design-system/DSButtom';
import DSAppBar from '../../../design-system/DSAppBar';
import DSPaper from '../../../design-system/Paper/DSPaper';
import logo from '../../../images/klydo-yellow.png';
import useProfileForm from '../hooks/useProfileForm';
import { User } from '../../../api/user';

export type ProfileFormProps = Pick<
  User,
  'name' | 'email' | 'description' | 'photoUrl' | 'country' | 'socialMedia'
> & {
  onSave: (updatedUser: Partial<User>) => void;
};

const ProfileForm = (props: ProfileFormProps) => {
  const {
    name,
    handleNameChange,
    description,
    setDescription,
    photoUrl,
    handleFileSelection,
    fileInputRef,
    handleRemoveImage,
    handleSave,
    country,
    setCountry,
    socialMedia,
    setSocialMedia,
    handleAddSocialMedia,
    handleRemoveSocialMedia,
    theme,
  } = useProfileForm(props);

  return (
    <DSPaper
      sx={{
        overflow: 'hidden',
        minHeight: '100vh',
      }}
    >
      <DSAppBar
        onClick={() => {
          window.location.href = 'https://www.klydoclock.com/';
        }}
        position="fixed"
        color="transparent"
        sx={{
          cursor: 'pointer',
          backgroundColor: theme.palette.background.paper,
          paddingY: { xs: 2, md: 4 },
          display: 'flex',
          justifyContent: 'center',
          textAlign: 'center',
          alignItems: 'center',
          boxShadow: 'none',
        }}
      >
        <img width={'130'} src={logo} />
      </DSAppBar>
      <DSBox
        sx={{
          overflow: 'auto',
          backgroundColor: theme.palette.background.paper,
          paddingTop: { xs: 12, md: 15 },
          height: '100vh',
          paddingBottom: { xs: 10, md: 20 },
          display: 'flex',
          flexDirection: 'column',
          textAlign: 'center',
          alignItems: 'center',
          justifyContent: 'start',
          overflowX: 'hidden',
        }}
      >
        <DSTypography
          lineHeight={1.2}
          sx={{
            fontSize: { xs: 35, md: 47.52 },
            maxWidth: { xs: '270px', md: '1080px' },
            fontFamily: 'Gilroy-Bold',
            fontWeight: 400,
          }}
        >
          Complete Your Profile
        </DSTypography>
        <DSBox
          width={'100%'}
          sx={{
            backgroundColor: theme.palette.background.paper,
            paddingTop: { xs: 2, md: 5 },
            paddingBottom: { xs: 10, md: 0 },
          }}
        >
          <DSBox
            sx={{
              display: 'flex',
              justifyContent: 'center',
              flexDirection: { xs: 'column', md: 'row-reverse' },
              alignItems: 'center',
              textAlign: 'left',
              gap: { xs: 0.5, md: 5 },
            }}
          >
            <DSBox
              sx={{
                flexDirection: 'column',
                alignItems: 'center',
                justifyContent: 'center',
                textAlign: 'center',
                position: 'relative',
              }}
            >
              <DSTypography
                variant="h6"
                lineHeight={'18.96px'}
                fontSize={'16px'}
                fontWeight={400}
                fontFamily={'Gilroy-SemiBold'}
                color={'#C9C9C9'}
              >
                {photoUrl ? 'Change photo' : 'Add your photo'}
              </DSTypography>
              <DSBox py={0.5} />
              <DSBox
                gap={0}
                position="relative"
                display="inline-block"
                sx={{ textAlign: 'right' }}
              >
                {photoUrl && (
                  <DSIconButton
                    onClick={handleRemoveImage}
                    style={{
                      backgroundColor: 'transparent',
                      borderRadius: '50%',
                      position: 'absolute',
                      left: 110,
                      top: -13,
                      color: 'white',
                      cursor: 'pointer',
                      fontFamily: 'Gilroy-Bold',
                      fontSize: 20,
                    }}
                  >
                    <ClearIcon />
                  </DSIconButton>
                )}
                <DSAvatar
                  onClick={() =>
                    fileInputRef.current && fileInputRef.current.click()
                  }
                  sx={{ width: 140, height: 140, cursor: 'pointer' }}
                >
                  <img
                    src={photoUrl}
                    alt="User Default"
                    style={{
                      width: '100%',
                      height: '100%',
                      objectFit: 'cover',
                      borderRadius: '50%',
                      border: '3px solid white',
                      backgroundColor: KLYDO_BLACK,
                    }}
                  />
                </DSAvatar>
                {photoUrl ? (
                  <></>
                ) : (
                  <DSBox
                    onClick={() =>
                      fileInputRef.current && fileInputRef.current.click()
                    }
                    sx={{
                      position: 'absolute',
                      top: 0,
                      left: 0,
                      width: '100%',
                      height: '100%',
                      display: 'flex',
                      justifyContent: 'center',
                      alignItems: 'center',
                      textAlign: 'center',
                      borderRadius: '50%',
                      backgroundColor: 'rgba(0, 0, 0, 0.8)',
                      transition: 'transform 0.3s ease',
                      cursor: 'pointer',
                      '&:hover': {
                        transform: 'scale(1.1)',
                      },
                    }}
                  >
                    <AddIcon sx={{ fontSize: 90, opacity: 0.75 }} />
                  </DSBox>
                )}
                <input
                  type="file"
                  ref={fileInputRef}
                  style={{ display: 'none' }}
                  accept="image/*"
                  onChange={handleFileSelection}
                />
              </DSBox>
            </DSBox>
            <DSBox
              sx={{
                display: 'flex',
                justifyContent: 'center',
                flexDirection: 'column',
                textAlign: 'left',
                width: { xs: '80%', md: '30%' },
              }}
            >
              <DSBox>
                <DSTypography
                  variant="h6"
                  lineHeight={'18.96px'}
                  fontSize={'16px'}
                  fontWeight={400}
                  color={'#C9C9C9'}
                  fontFamily={'Gilroy-SemiBold'}
                >
                  *Name
                </DSTypography>
                <DSBox py={0.5} />
                <DSTextField
                  placeholder="Danny"
                  value={name}
                  fullWidth
                  onChange={(e) => handleNameChange(e.target.value)}
                  required
                  slotProps={{
                    input: {
                      inputProps: { maxLength: 14 },
                    },
                  }}
                  sx={{
                    backgroundColor: '#212121',
                    '.MuiInputBase-input': {
                      fontSize: '15px',
                    },
                  }}
                />
              </DSBox>
              <DSBox py={1} />
              <DSBox>
                <DSTypography
                  variant="h6"
                  lineHeight={'18.96px'}
                  fontSize={'16px'}
                  fontWeight={400}
                  color={'#C9C9C9'}
                  fontFamily={'Gilroy-SemiBold'}
                >
                  Description
                </DSTypography>
                <DSBox py={0.5} />
                <DSTextField
                  placeholder="Describe yourself..."
                  value={description}
                  onChange={(e) => setDescription(e.target.value)}
                  multiline
                  fullWidth
                  maxRows={6}
                  slotProps={{
                    input: {
                      inputProps: { maxLength: 430 },
                      style: {
                        padding: '20px',
                      },
                    },
                  }}
                  sx={{
                    backgroundColor: '#212121',
                    '.MuiInputBase-input': {
                      overflowWrap: 'break-word',
                      fontSize: '15px',
                    },
                  }}
                />
                <DSBox py={0.5} />
                <DSTypography
                  variant="h6"
                  lineHeight={'18.96px'}
                  fontSize={'16px'}
                  fontWeight={400}
                  color={'#C9C9C9'}
                  fontFamily={'Gilroy-SemiBold'}
                >
                  {description?.length}/430
                </DSTypography>
              </DSBox>
              <DSBox py={1} />
              <DSTypography
                variant="h6"
                lineHeight={'18.96px'}
                fontSize={'16px'}
                fontWeight={400}
                color={'#C9C9C9'}
                fontFamily={'Gilroy-SemiBold'}
              >
                Country
              </DSTypography>
              <DSBox py={0.5} />
              <DSTextField
                placeholder="Country"
                value={country}
                onChange={(e) => setCountry(e.target.value)}
                fullWidth
                sx={{
                  backgroundColor: '#212121',
                  '.MuiInputBase-input': {
                    fontSize: '15px',
                  },
                }}
              />

              <DSBox py={1} />
              <DSTypography
                variant="h6"
                lineHeight={'18.96px'}
                fontSize={'16px'}
                fontWeight={400}
                color={'#C9C9C9'}
                fontFamily={'Gilroy-SemiBold'}
              >
                Social Media
              </DSTypography>
              <DSBox py={0.5} />
              {socialMedia.map((sm, index) => (
                <DSBox key={index} display="flex" alignItems="center" gap={2}>
                  <DSTextField
                    placeholder="Network (e.g., Instagram)"
                    value={sm.network}
                    onChange={(e) =>
                      setSocialMedia(
                        socialMedia.map((item, i) =>
                          i === index
                            ? { ...item, network: e.target.value }
                            : item,
                        ),
                      )
                    }
                  />
                  <DSTextField
                    placeholder="Link"
                    value={sm.link}
                    onChange={(e) =>
                      setSocialMedia(
                        socialMedia.map((item, i) =>
                          i === index
                            ? { ...item, link: e.target.value }
                            : item,
                        ),
                      )
                    }
                  />
                  <DSIconButton
                    sx={{ alignSelf: 'flex-start' }}
                    onClick={() => handleRemoveSocialMedia(index)}
                  >
                    <ClearIcon />
                  </DSIconButton>
                </DSBox>
              ))}
              <DSButton
                sx={{ color: KLYDO_WHITE, textAlign: 'left' }}
                onClick={handleAddSocialMedia}
              >
                <AddIcon /> Add Social Media
              </DSButton>
            </DSBox>
          </DSBox>
        </DSBox>
        <DSBox sx={{ paddingY: { xs: 0, md: 4 } }} />
        <DSBox display="flex" gap={2} justifyContent="center">
          <DSButton variant="contained" onClick={handleSave}>
            Save
          </DSButton>
          <DSButton variant="outlined" onClick={handleSave}>
            Do it Later
          </DSButton>
        </DSBox>
      </DSBox>
    </DSPaper>
  );
};

export default ProfileForm;
