import { useInfiniteQuery, useQueryClient } from '@tanstack/react-query';
import { useEffect, useRef } from 'react';
import packApiClient, { PackUnion } from '../../../api/packs';
import { ApprovedKlydo } from '../../../api/klydo';

const fetchPacks = async (
  pageParam: { skip?: number; limit?: number },
  queryClient: ReturnType<typeof useQueryClient>,
): Promise<PackUnion[]> => {
  const { skip = 0, limit } = pageParam;
  const res = await packApiClient.getPacks(skip, limit);

  res.forEach((pack) => {
    queryClient.setQueryData(['pack', pack.id], pack);
  });

  const klydos = res
    .map((pack) => pack.klydos)
    .flat()
    .filter(Boolean) as ApprovedKlydo[];

  for (const klydo of klydos) {
    queryClient.setQueryData(['klydo', klydo.id], klydo);
  }
  return res;
};

const usePacksList = () => {
  const dataRef = useRef<PackUnion[]>();
  const queryClient = useQueryClient();
  const limit = 10;
  const { data, fetchNextPage, ...rest } = useInfiniteQuery<PackUnion[], Error>(
    {
      queryKey: ['packs'],
      queryFn: ({ pageParam }) =>
        fetchPacks(pageParam as { skip?: number; limit?: number }, queryClient),
      getNextPageParam: (lastPage, allPages) => {
        const currentLength = allPages?.flat()?.length;
        return currentLength < limit ? undefined : { skip: currentLength };
      },
      initialPageParam: { skip: 0, limit: limit },
    },
  );
  const flatData = data?.pages.flat();

  useEffect(() => {
    dataRef.current = flatData;
  }, [flatData]);

  return { data: flatData, dataRef, fetchNextPage, ...rest };
};
export default usePacksList;
