import { baseClient } from './baseRequest';
import { ApprovedKlydo } from './klydo';

export enum PackReviewStates {
  Published = 'published',
  Pending = 'pending',
  Rejected = 'rejected',
  Draft = 'draft',
}

export enum PackType {
  Draft = 'DraftPack',
  Published = 'PublishedPack',
}

export type BasePack = {
  id: string;
  name: string;
  cover: string;
  logo: string;
  klydoIds: string[];
  klydos?: ApprovedKlydo[];
  description: string;
  createdAt: Date;
  updatedAt: Date;
  creatorId: string;
};

export type DraftPack = BasePack & {
  type: PackType.Draft;
  reviewStatus: {
    state: PackReviewStates;
    message?: string;
  };
};

export type PublishedPack = BasePack & {
  type: PackType.Published;
};

export type PackUnion = DraftPack | PublishedPack;

export type PackApiClient = {
  createPack: (pack: Partial<DraftPack>) => Promise<DraftPack>;
  updatePack: (packId: string, pack: Partial<DraftPack>) => Promise<void>;
  deletePack: (packId: string) => Promise<void>;
  getPackById: (packId: string) => Promise<PackUnion>;
  getPacks: (skip?: number, limit?: number) => Promise<PackUnion[]>;
};

const packApiClient: PackApiClient = {
  createPack: async (pack) => {
    return baseClient.post<DraftPack>({
      url: `/packs`,
      body: pack,
    });
  },
  updatePack: async (packId, pack) => {
    return baseClient.put<void>({
      url: `/packs/${packId}`,
      body: pack,
    });
  },
  deletePack: async (packId) => {
    return baseClient.delete<void>({
      url: `/packs/${packId}`,
    });
  },
  getPackById: async (packId) => {
    return baseClient.get<PackUnion>({
      url: `/packs/${packId}`,
    });
  },
  getPacks: async (skip, limit) => {
    if (!skip || !limit) {
      return baseClient.get<PackUnion[]>({
        url: `/packs`,
      });
    }
    return baseClient.get<PackUnion[]>({
      url: `/packs?skip=${skip}&limit=${limit}`,
    });
  },
};

export default packApiClient;
