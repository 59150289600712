import { KLYDO_WHITE } from '../../../../consts/colors';
import DSBox from '../../../../design-system/DSBox';
import { Theme } from '../../../../types';

export type KlydoColorStackItemProps = Partial<Theme> & {
  isSelected?: boolean;
};

const KlydoColorStackItem: React.FC<KlydoColorStackItemProps> = ({
  isSelected,
  backgroundColor,
  handsColor,
  pendulumColor,
  pendulumRodColor,
  dialsColor,
}) => {
  const ColorCircle = ({ color }: { color?: string }) => {
    return (
      <DSBox
        sx={{
          width: 45,
          height: 45,
          borderRadius: '50%',
          backgroundColor: color || 'rgba(255, 255, 255, 0.23)',
        }}
      />
    );
  };
  return (
    <DSBox
      sx={{
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'center',
        alignSelf: 'stretch',
        gap: 2,
        padding: 2,
        borderRadius: 'var(--3, 24px)',
        borderColor: isSelected ? KLYDO_WHITE : 'rgba(255, 255, 255, 0.23)',
        borderWidth: 3,
        borderStyle: 'solid',
      }}
    >
      <ColorCircle color={backgroundColor} />
      <ColorCircle color={handsColor} />
      <ColorCircle color={pendulumColor} />
      <ColorCircle color={pendulumRodColor} />
      <ColorCircle color={dialsColor} />
    </DSBox>
  );
};

export default KlydoColorStackItem;
