import React from 'react';
import { AppContext } from './AppContext';
import AuthInitializer from './AuthInitializer';
import UserInitializer from './UserInitializer';
import useAppContextProvider from './hooks/useAppContextProvider';

type Props = {
  children: React.ReactNode;
};

const AppContextProvider: React.FC<Props> = ({ children }) => {
  const { user, setUser, authUser, setAuthUser, isAdmin, isImpersonating } =
    useAppContextProvider();
  return (
    <AppContext.Provider
      value={{
        user: user!,
        setUser,
        authUser: authUser!,
        isAdmin,
        isImpersonating,
      }}
    >
      <AuthInitializer setAuthUser={setAuthUser} isAdmin={isAdmin}>
        {authUser && (
          <UserInitializer
            authUser={authUser}
            setUser={setUser}
            isAdmin={isAdmin}
          >
            {children}
          </UserInitializer>
        )}
      </AuthInitializer>
    </AppContext.Provider>
  );
};

export default AppContextProvider;
