import React from 'react';
import DSBox from '../../../design-system/DSBox';
import DSTextField from '../../../design-system/DSTextField';
import DSPackLogoAvatar from '../../../design-system/Pack/DSPackLogoAvatar';
import { DSPackCoverAvatar } from '../../../design-system/Pack';
import { Input, useTheme } from '@mui/material';
import packBackgorundLogo from '../../../images/pack-background.png';
import AddIcon from '@mui/icons-material/Add';
import DSLabelTypography from '../../../design-system/Typography/DSLabelTypography';
import HelpOutlineOutlinedIcon from '@mui/icons-material/HelpOutlineOutlined';
import DSIconButton from '../../../design-system/DSIconButton';

export type CreatePackFormProps = {
  name: string;
  setName: (name: string) => void;
  description: string;
  setDescription: (description: string) => void;
  cover: string;
  setCover: (cover: string) => void;
  logo: string;
  setLogo: (logo: string) => void;
  handleFileChange: (
    folder: string,
    setImage: (url: string) => void,
    event: React.ChangeEvent<HTMLInputElement>,
  ) => void;
};

const CreatePackForm: React.FC<CreatePackFormProps> = ({
  name,
  setName,
  description,
  setDescription,
  cover,
  setCover,
  logo,
  setLogo,
  handleFileChange,
}) => {
  const { spacing, palette } = useTheme();
  return (
    <DSBox
      sx={{
        display: 'flex',
        flexDirection: 'column',
        gap: spacing(3),
        width: '100%',
      }}
    >
      <DSBox
        sx={{
          display: 'flex',
          flexDirection: 'row',
          justifyContent: 'space-between',
          width: '100%',
        }}
      >
        <DSBox
          sx={{
            display: 'flex',
            flexDirection: 'column',
            gap: spacing(2),
            justifyContent: 'center',
            alignItems: 'center',
          }}
        >
          <DSBox
            sx={{
              display: 'flex',
              flexDirection: 'row',
              gap: spacing(1),
              alignItems: 'center',
            }}
          >
            <DSLabelTypography>Add a Pack Cover</DSLabelTypography>
            <HelpOutlineOutlinedIcon
              sx={{
                color: palette.action.active,
                width: spacing(2),
                height: spacing(2),
              }}
            />
          </DSBox>

          {cover ? (
            <DSPackCoverAvatar
              src={cover}
              sx={{
                cursor: 'pointer',
                overflow: 'hidden',
              }}
            />
          ) : (
            <DSBox
              sx={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                width: spacing(40),
                height: spacing(40),
                borderRadius: spacing(13),
                background: palette.action.disabledBackground,
                cursor: 'pointer',
                overflow: 'hidden',
              }}
            >
              <DSIconButton component="label">
                <AddIcon
                  sx={{
                    height: spacing(7),
                    width: spacing(7),
                    color: palette.action.active,
                  }}
                />
                <Input
                  type="file"
                  inputProps={{ accept: '.png, .jpeg, .jpg' }}
                  style={{ display: 'none' }}
                  onChange={(e) =>
                    handleFileChange(
                      'pack_covers',
                      setCover,
                      e as React.ChangeEvent<HTMLInputElement>,
                    )
                  }
                />
              </DSIconButton>
            </DSBox>
          )}
        </DSBox>
        <DSBox
          sx={{
            display: 'flex',
            flexDirection: 'column',
            width: { md: spacing(82), lg: spacing(110), xl: spacing(165) },
            gap: spacing(3),
          }}
        >
          <DSBox
            sx={{ display: 'flex', flexDirection: 'column', gap: spacing(1) }}
          >
            <DSLabelTypography>Name Your Pack</DSLabelTypography>
            <DSTextField
              value={name}
              placeholder="My Klydo Pack"
              onChange={(e) => setName(e.target.value)}
              fullWidth
              slotProps={{
                input: {
                  inputProps: { maxLength: 70 },
                },
              }}
            />
          </DSBox>
          <DSBox
            sx={{ display: 'flex', flexDirection: 'row', gap: spacing(5) }}
          >
            <DSBox
              sx={{
                display: 'flex',
                flexDirection: 'column',
                gap: spacing(1),
                alignItems: 'center',
              }}
            >
              <DSBox
                sx={{
                  display: 'flex',
                  flexDirection: 'row',
                  gap: spacing(1),
                  alignItems: 'center',
                }}
              >
                <DSLabelTypography>Add a Pack Symbol</DSLabelTypography>
                <HelpOutlineOutlinedIcon
                  sx={{
                    color: palette.action.active,
                    width: spacing(2),
                    height: spacing(2),
                  }}
                />
              </DSBox>

              {logo ? (
                <DSPackLogoAvatar
                  src={logo}
                  sx={{
                    cursor: 'pointer',
                  }}
                />
              ) : (
                <DSBox
                  sx={{
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    width: spacing(25),
                    height: spacing(25),
                    borderRadius: '50%',
                    cursor: 'pointer',
                    overflow: 'hidden',
                    backgroundImage: `url(${packBackgorundLogo})`,
                  }}
                >
                  <DSIconButton component="label">
                    <AddIcon
                      sx={{
                        height: spacing(7),
                        width: spacing(7),
                        color: palette.action.active,
                      }}
                    />
                    <Input
                      type="file"
                      inputProps={{ accept: '.png, .jpeg, .jpg' }}
                      style={{ display: 'none' }}
                      onChange={(e) =>
                        handleFileChange(
                          'pack_logos',
                          setLogo,
                          e as React.ChangeEvent<HTMLInputElement>,
                        )
                      }
                    />
                  </DSIconButton>
                </DSBox>
              )}
            </DSBox>
            <DSBox
              sx={{
                display: 'flex',
                flexDirection: 'column',
                gap: spacing(1),
                width: '100%',
                height: 'auto',
              }}
            >
              <DSLabelTypography>Pack Description</DSLabelTypography>
              <DSTextField
                value={description}
                onChange={(e) => setDescription(e.target.value)}
                multiline
                rows={6}
                fullWidth
                placeholder="My Pack is special since..."
              />
            </DSBox>
          </DSBox>
        </DSBox>
      </DSBox>
      <DSBox
        sx={{
          display: 'flex',
          flexDirection: 'row',
          justifyContent: 'space-between',
          width: '100%',
          gap: spacing(4),
        }}
      >
        <DSBox
          sx={{
            display: 'flex',
            flexDirection: 'column',
            gap: spacing(1),
            width: '100%',
          }}
        >
          <DSLabelTypography>Price</DSLabelTypography>
          <DSTextField disabled fullWidth placeholder="$ --" />
        </DSBox>
        <DSBox
          sx={{
            display: 'flex',
            flexDirection: 'column',
            gap: spacing(1),
            width: '100%',
          }}
        >
          <DSLabelTypography>Location</DSLabelTypography>
          <DSTextField disabled fullWidth placeholder="Global" />
        </DSBox>
        <DSBox
          sx={{
            display: 'flex',
            flexDirection: 'column',
            gap: spacing(1),
            width: '100%',
          }}
        >
          <DSLabelTypography>Duration</DSLabelTypography>
          <DSTextField disabled fullWidth placeholder="Always" />
        </DSBox>
        <DSBox
          sx={{
            display: 'flex',
            flexDirection: 'column',
            gap: spacing(1),
            width: '100%',
          }}
        >
          <DSLabelTypography>Visibility</DSLabelTypography>
          <DSTextField
            onChange={(e) => setDescription(e.target.value)}
            disabled
            fullWidth
            placeholder="Public"
          />
        </DSBox>
      </DSBox>
    </DSBox>
  );
};

export default CreatePackForm;
