import { BrowserRouter, Route, Routes } from 'react-router-dom';
import './App.css';
import Dashboard from './views/Dashboard/components/Dashboard';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import AppContextProvider from './contexts/AppContextProvider';
import { createContext, ReactNode, useEffect, useState } from 'react';
import firebaseService from './firebase/firebaseService';
import firebase from 'firebase/compat/app';
import ProfileEditable from './views/Profile/components/ProfileEditable';
import MainLayout from './MainLayout';
import Profile from './views/Profile/components/Profile';
import CreatePackContainer from './views/Packs/components/CreatePackContainer';
import CreateKlydo from './views/Klydos/components/CreateKlydo';
import Packs from './views/Packs/components/Packs';
import Klydos from './views/Klydos/KlydosList/components/Klydos';
import * as Sentry from '@sentry/react';

const queryClient = new QueryClient();

interface AuthContextType {
  user: firebase.User | null;
  setUser: React.Dispatch<React.SetStateAction<firebase.User | null>>;
}

export const AuthContext = createContext<AuthContextType | undefined>(
  undefined,
);
const SentryRoutes = Sentry.withSentryReactRouterV7Routing(Routes);

export const AuthProvider = ({ children }: { children: ReactNode }) => {
  const [user, setUser] = useState<firebase.User | null>(null);

  useEffect(() => {
    const unsubscribe: firebase.Unsubscribe =
      firebaseService.onAuthStateChanged((authUser: firebase.User | null) => {
        setUser(authUser);
      });
    return () => unsubscribe();
  }, []);

  return (
    <AuthContext.Provider value={{ user, setUser }}>
      {children}
    </AuthContext.Provider>
  );
};
function App() {
  return (
    <QueryClientProvider client={queryClient}>
      <BrowserRouter>
        <AppContextProvider>
          <SentryRoutes>
            <Route element={<MainLayout />}>
              <Route path="/*" element={<Dashboard />} />
              <Route path="/profile" element={<Profile />} />
              <Route path="/edit-profile" element={<ProfileEditable />} />
              <Route path="/create-klydo" element={<CreateKlydo />} />
              <Route path="/edit-pack" element={<CreatePackContainer />} />
              <Route path={'/dashboard'} element={<Dashboard />} />
              <Route path={'/packs'} element={<Packs />} />
              <Route path={'/klydos'} element={<Klydos />} />
            </Route>
          </SentryRoutes>
        </AppContextProvider>
      </BrowserRouter>
    </QueryClientProvider>
  );
}
export default App;
