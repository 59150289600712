import { Theme } from '../types';
import { baseClient } from './baseRequest';

export enum KlydoType {
  Draft = 'DraftKlydo',
  Approved = 'ApprovedKlydo',
}

export enum KlydoReviewStates {
  Pending = 'pending',
  Rejected = 'rejected',
  Draft = 'draft',
}

export type BaseKlydo = {
  name: string;
  loopUrl: string;
  convertedLoopUrl?: string;
  theme: Theme;
  id: string;
  creator: string;
  public: boolean;
  dials: boolean;
  klydoSecureUrl?: string;
  createdAt: Date;
  updatedAt: Date;
  authorName: string;
};

export type ApprovedKlydo = BaseKlydo & {
  type: KlydoType.Approved;
  stats: {
    favorites: number;
    viewed: number;
    time: number;
  };
};

export type DraftKlydo = BaseKlydo & {
  type: KlydoType.Draft;
  reviewStatus: {
    state: KlydoReviewStates;
    message?: string;
  };
};

export type KlydoUnion = DraftKlydo | ApprovedKlydo;

export type KlydoApiClient = {
  processImage: (loopUrl: string) => Promise<Theme[]>;
  checkContrast: (filePath: string, handColor: string) => Promise<string>;
  search: (query: string, options: KlydoSearchOptions) => Promise<KlydoUnion[]>;
  approvedKlydosTextSearch: (
    query: string,
    options: KlydoSearchOptions,
  ) => Promise<ApprovedKlydo[]>;
  createKlydo: (klydo: Partial<DraftKlydo>) => Promise<DraftKlydo>;
  deleteKlydo: (klydoId: string) => Promise<void>;
  updateKlydo: (klydoId: string, klydo: Partial<DraftKlydo>) => Promise<void>;
};

export type KlydoSearchFilter = { excludeIds: string[] };

export type KlydoSearchOptions = {
  limit: number;
  skip: number;
  sort: { [key: string]: number };
};

const klydoApiClient: KlydoApiClient = {
  processImage: async (loopUrl: string) => {
    return baseClient.post({
      url: `/klydos/process-image`,
      body: {
        loopUrl: loopUrl,
      },
    });
  },

  checkContrast: async (filePath: string, handColor: string) => {
    return baseClient.post<string>({
      url: `/klydos/check-contrast`,
      body: {
        filePath: filePath,
        handColor: handColor,
      },
    });
  },
  search: async (query: string, options: KlydoSearchOptions) => {
    return baseClient.post({
      url: `/studio/.search/text`,
      body: { query, options },
    });
  },
  approvedKlydosTextSearch: async (
    query: string,
    options: KlydoSearchOptions,
  ) => {
    return baseClient.post({
      url: `/studio/.search/approved-klydos-text-search`,
      body: { query, options },
    });
  },
  createKlydo: async (klydo: Partial<DraftKlydo>) => {
    return baseClient.post<DraftKlydo>({
      url: '/studio/klydo',
      body: klydo,
    });
  },
  deleteKlydo: async (klydoId: string) => {
    return baseClient.delete({ url: `/studio/klydo/${klydoId}` });
  },
  updateKlydo: async (klydoId: string, klydo: Partial<DraftKlydo>) => {
    return baseClient.put({
      url: `/studio/klydo/${klydoId}`,
      body: klydo,
    });
  },
};

export default klydoApiClient;
