import React from 'react';
import { Avatar, useTheme } from '@mui/material';

const DSPackCoverAvatar = (props: React.ComponentProps<typeof Avatar>) => {
  const { spacing } = useTheme();
  return (
    <Avatar
      {...props}
      sx={{
        ...props.sx,
        width: spacing(40),
        height: spacing(40),
        borderRadius: spacing(13),
      }}
    />
  );
};

export default DSPackCoverAvatar;
