import {
  QueryKey,
  useQuery,
  UseQueryOptions,
  UseQueryResult,
  keepPreviousData,
} from '@tanstack/react-query';
import { KlydoError } from './types';

export type UseKlydoQueryResult<TData> = UseQueryResult<TData, KlydoError>;
export type UseKlydoQueryOptions<
  TQueryFnData,
  TData,
  TQueryKey extends QueryKey = QueryKey,
> = UseQueryOptions<TQueryFnData, KlydoError, TData, TQueryKey> & {
  keepPreviousData?: boolean;
};

const useKlydoQuery = <
  TQueryFnData = unknown,
  TData = TQueryFnData,
  TQueryKey extends QueryKey = QueryKey,
>(
  options: UseKlydoQueryOptions<TQueryFnData, TData, TQueryKey>,
): UseKlydoQueryResult<TData> => {
  if (options.keepPreviousData) {
    options.placeholderData = keepPreviousData;
  }
  return useQuery(options);
};

export default useKlydoQuery;
