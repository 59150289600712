import { Input } from '@mui/material';
import DSBox from '../../../design-system/DSBox';
import DSIconButton from '../../../design-system/DSIconButton';
import { DSTypography } from '../../../design-system/Typography';
import DSLabelTypography from '../../../design-system/Typography/DSLabelTypography';
import DSValueTypography from '../../../design-system/Typography/DSValueTypography';
import AddIcon from '@mui/icons-material/Add';
import useKlydoFileInput from '../hooks/useKlydoFileInput';
import CloseIcon from '@mui/icons-material/Close';
import KlydoLoopUrl from '../../../components/klydoPreview/KlydoLoopUrl';
import { resizeCloudinary } from '../../../utils';
import DSCircularProgress from '../../../design-system/DSCircularProgress';
import DSTooltip from '../../../design-system/DSTooltip';
import HelpOutline from '@mui/icons-material/HelpOutline';
import DSPaper from '../../../design-system/Paper/DSPaper';

type KlydoFileInputProps = {
  klydoSecureUrl: string;
  onFileChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
  memoryUsage: number;
  onRemoveFile: () => void;
  fileName: string;
  isLoadingUrl: boolean;
};

const KlydoFileInput = ({
  klydoSecureUrl,
  onFileChange,
  memoryUsage,
  onRemoveFile,
  fileName,
  isLoadingUrl,
}: KlydoFileInputProps) => {
  const { spacing, handleFileChange, palette, handleRemoveFile } =
    useKlydoFileInput({
      onFileChange,
      onRemoveFile,
    });

  return (
    <DSBox sx={{ display: 'flex', flexDirection: 'column', gap: spacing(1) }}>
      <DSBox
        sx={{
          width: '100%',
          display: 'flex',
          flexDirection: 'row',
        }}
      >
        <DSLabelTypography>*Choose File</DSLabelTypography>
        <DSTooltip title="text try">
          <DSIconButton
            sx={{ height: spacing(1), color: palette.text.secondary }}
          >
            <HelpOutline />
          </DSIconButton>
        </DSTooltip>
      </DSBox>
      <DSPaper
        sx={{
          backgroundColor: 'rgba(255, 255, 255, 0.09)',
          alignItems: 'center',
          display: 'flex',
          justifyContent: 'center',
          flexDirection: 'column',
          alignSelf: 'stretch',
          width: '100%',
          height: 'auto',
          borderRadius: '28px',
          padding: spacing(2),
          paddingTop: spacing(3),
          gap: spacing(1),
        }}
      >
        {!klydoSecureUrl ? (
          isLoadingUrl ? (
            <DSCircularProgress />
          ) : (
            <DSBox
              sx={{
                justifyContent: 'center',
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
              }}
            >
              <DSIconButton
                component="label"
                sx={{
                  backgroundColor: palette.action.disabled,
                  fontSize: '24px',
                  padding: '12px',
                  width: spacing(6),
                  boxShadow:
                    '0px 1px 18px 0px rgba(0, 0, 0, 0.12), 0px 6px 10px 0px rgba(0, 0, 0, 0.14), 0px 3px 5px -1px rgba(0, 0, 0, 0.20)',
                }}
              >
                <AddIcon />
                <Input
                  inputProps={{ accept: '.mp4, .gif' }}
                  type="file"
                  onChange={handleFileChange}
                  style={{
                    display: 'none',
                  }}
                />
              </DSIconButton>
              <DSValueTypography color={'rgba(119, 119, 119, 1)'}>
                MP4, Gif max size 10 MB
              </DSValueTypography>
            </DSBox>
          )
        ) : (
          <DSBox sx={{ width: '100%' }}>
            <DSBox
              sx={{
                display: 'flex',
                flexDirection: 'row',
                justifyContent: 'space-between',
              }}
            >
              <DSBox
                sx={{
                  flexDirection: 'row',
                  display: 'flex',
                  alignItems: 'center',
                  gap: spacing(1),
                  height: spacing(10),
                }}
              >
                <DSBox sx={{ width: spacing(10), height: spacing(10) }}>
                  <KlydoLoopUrl
                    loopUrl={resizeCloudinary(klydoSecureUrl, 48)}
                  />
                </DSBox>
                <DSTypography>{fileName}</DSTypography>
              </DSBox>

              <DSIconButton onClick={handleRemoveFile}>
                <CloseIcon />
              </DSIconButton>
            </DSBox>
            <DSTypography sx={{ display: 'flex', justifyContent: 'center' }}>
              {Math.round(memoryUsage)}MB Memory Usage
            </DSTypography>
          </DSBox>
        )}
      </DSPaper>
    </DSBox>
  );
};

export default KlydoFileInput;
