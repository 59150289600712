import { useState } from 'react';
import { ApprovedKlydo } from '../../../api/klydo';
import { useTheme } from '@mui/material';
import useApprovedKlydosList from './useApprovedKlydosList';

export type UsePickKlydosDialogProps = {
  onPick: (klydos: ApprovedKlydo[]) => void;
  onClose: () => void;
};
const MAX_KLYDOS = 12;

const usePickKlydosDialog = ({ onPick, onClose }: UsePickKlydosDialogProps) => {
  const { spacing } = useTheme();
  const [selectedKlydos, setSelectedKlydos] = useState<ApprovedKlydo[]>([]);
  const {
    data: klydos,
    isLoading,
    fetchNextPage,
    isFetchingNextPage,
    hasNextPage,
  } = useApprovedKlydosList();

  const toggleKlydoSelection = (klydo: ApprovedKlydo) => {
    setSelectedKlydos((prev) => {
      if (prev.some((selected) => selected.id === klydo.id)) {
        return prev.filter((selected) => selected.id !== klydo.id);
      } else if (prev.length < MAX_KLYDOS) {
        return [...prev, klydo];
      } else {
        alert(`You can select up to ${MAX_KLYDOS} Klydos.`);
        return prev;
      }
    });
  };

  const handleSave = () => {
    const uniqueKlydos = Array.from(new Set(selectedKlydos));
    onPick(uniqueKlydos);
    onClose();
  };

  return {
    klydos,
    isLoading,
    selectedKlydos,
    fetchNextPage,
    isFetchingNextPage,
    hasNextPage,
    toggleKlydoSelection,
    handleSave,
    spacing,
  };
};

export default usePickKlydosDialog;
