import DSBox from '../../../design-system/DSBox';
import useCreatePack from '../hooks/useCreatePack';
import CreatePackForm from './CreatePackForm';
import PickKlydosModal from './PickKlydosModal';
import DSGrid from '../../../design-system/DSGrid';
import KlydoGridItem from '../../Klydos/KlydosList/components/KlydoGridItem';
import DSGradientViewPaper from '../../../design-system/Paper/DSGradientViewPaper';
import { ArrowBack } from '@mui/icons-material';
import DSTabHeaderTypography from '../../../design-system/Typography/DSSTabHeaderTypography';
import { DSTypography } from '../../../design-system/Typography';
import PackStatus from './PackStatus';
import { PackReviewStates } from '../../../api/packs';
import DSKlydoCard from '../../../design-system/Klydo/DSKlydoCard';
import AddIcon from '@mui/icons-material/Add';
import DSPrimaryButton from '../../../design-system/DSPrimaryButtom';

const CreatePackContainer = () => {
  const {
    name,
    setName,
    description,
    setDescription,
    logo,
    setLogo,
    cover,
    setCover,
    updatedAt,
    handleFileChange,
    handleSubmitPack,
    klydos,
    isPickModalOpen,
    handleOpenPickModal,
    handleClosePickModal,
    handlePickKlydos,
    spacing,
    palette,
    isSubmitDisable,
    handleBack,
  } = useCreatePack();

  return (
    <DSBox>
      <DSGradientViewPaper
        sx={{
          alignItems: 'center',
          flexDirection: 'column',
          margin: spacing(3),
          paddingTop: spacing(4),
          paddingX: spacing(4),
        }}
      >
        <DSBox
          sx={{
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'space-between',
            alignItems: 'center',
            width: '100%',
          }}
        >
          <DSBox
            sx={{
              display: 'flex',
              gap: spacing(1),
              flexDirection: 'row',
            }}
          >
            <ArrowBack
              onClick={() => handleBack()}
              sx={{
                fontSize: spacing(6),
                cursor: 'pointer',
              }}
            />
            <DSBox
              sx={{
                display: 'flex',
                gap: spacing(2),
                flexDirection: 'row',
                alignItems: 'center',
              }}
            >
              <DSTabHeaderTypography>Edit Pack</DSTabHeaderTypography>
              <PackStatus state={PackReviewStates.Draft} />
            </DSBox>
          </DSBox>

          <DSTypography sx={{ color: 'text.secondary' }}>
            Last Update{' '}
            {new Date(updatedAt)
              .toLocaleDateString(undefined, {
                year: '2-digit',
                month: '2-digit',
                day: '2-digit',
              })
              .replace(/\//g, '.')}
          </DSTypography>
        </DSBox>
        <CreatePackForm
          name={name}
          setName={setName}
          description={description}
          setDescription={setDescription}
          cover={cover}
          setCover={setCover}
          logo={logo}
          setLogo={setLogo}
          handleFileChange={handleFileChange}
        />
        <DSPrimaryButton
          sx={{
            padding: '14px var(--5, 40px)',
            display: 'flex',
            alignSelf: 'center',
            marginTop: spacing(4),
          }}
          disabled={isSubmitDisable}
          onClick={handleSubmitPack}
        >
          Publish
        </DSPrimaryButton>
      </DSGradientViewPaper>

      <DSBox
        sx={{
          display: 'flex',
          paddingBottom: 10,
          paddingX: spacing(4),
        }}
      >
        <DSKlydoCard>
          <AddIcon
            onClick={handleOpenPickModal}
            sx={{
              backgroundColor: palette.action.disabledBackground,
              height: spacing(8),
              width: spacing(8),
              padding: spacing(2),
              borderRadius: 50,
              color: palette.secondary.contrastText,
            }}
          />
        </DSKlydoCard>

        <DSGrid container spacing={2}>
          {klydos.map((klydo) => {
            return <KlydoGridItem key={klydo.id} klydo={klydo} />;
          })}
        </DSGrid>
      </DSBox>
      <PickKlydosModal
        isOpen={isPickModalOpen}
        onClose={handleClosePickModal}
        onPick={handlePickKlydos}
      />
    </DSBox>
  );
};

export default CreatePackContainer;
