import { Theme } from '../../../../types';
import { useState } from 'react';
import { useTheme } from '@mui/material';

const useKlydoColorPickerModal = (theme?: Theme) => {
  const { spacing, palette } = useTheme();
  const [updatedTheme, setUpdatedTheme] = useState<Theme>({
    dialsColor: theme?.dialsColor || '',
    handsColor: theme?.handsColor || '',
    pendulumRodColor: theme?.pendulumRodColor || '',
    pendulumColor: theme?.pendulumColor || '',
    backgroundColor: theme?.backgroundColor || '',
  });

  const [currentColorKey, setCurrentColorKey] = useState<keyof Theme | null>(
    null,
  );

  const handleColorPick = (key: keyof Theme) => {
    setCurrentColorKey(key);
  };

  const handleColorChange = (color: string) => {
    if (currentColorKey) {
      setUpdatedTheme((prev) => ({
        ...prev,
        [currentColorKey]: color,
      }));
    }
    setCurrentColorKey(null);
  };

  const disabledSave =
    updatedTheme.dialsColor === '' ||
    updatedTheme.handsColor === '' ||
    updatedTheme.pendulumRodColor === '' ||
    updatedTheme.pendulumColor === '' ||
    updatedTheme.backgroundColor === '';

  return {
    spacing,
    palette,
    disabledSave,
    updatedTheme,
    handleColorPick,
    handleColorChange,
  };
};
export default useKlydoColorPickerModal;
