import DSAvatar from '../../../design-system/DSAvatar';
import DSBox from '../../../design-system/DSBox';
import Flag from 'react-world-flags';
import useProfile from '../hooks/useProfile';
import DSTabHeaderTypography from '../../../design-system/Typography/DSSTabHeaderTypography';
import DSSecondaryButton from '../../../design-system/DSSecondaryButton';
import ProfileItemMenuButton from './ProfileItemMenuButton';
import DSLabelTypography from '../../../design-system/Typography/DSLabelTypography';
import DSValueTypography from '../../../design-system/Typography/DSValueTypography';
import DSGradientViewPaper from '../../../design-system/Paper/DSGradientViewPaper';
import SocialChipStack from './SocialChipStack';

const Profile = () => {
  const {
    handleLogout,
    handleEitProfile,
    spacing,
    name,
    email,
    photoUrl,
    description,
    country,
    socialMedia,
  } = useProfile();

  return (
    <DSBox
      sx={{
        display: 'flex',
        flexDirection: 'column',
        paddingLeft: spacing(3),
        paddingRight: spacing(3),
        paddingBottom: spacing(4),
        gap: spacing(3),
      }}
    >
      <DSBox
        sx={{
          display: 'flex',
          justifyContent: 'flex-end',
          width: '100%',
        }}
      >
        <ProfileItemMenuButton
          onLogout={handleLogout}
          onEdit={handleEitProfile}
        />
      </DSBox>
      <DSBox
        sx={{
          display: 'flex',
          alignItems: 'center',
          flexDirection: 'column',
          mt: {
            lg: spacing(-8),
          },
          gap: spacing(1),
        }}
      >
        <DSAvatar
          sx={{
            width: spacing(34),
            height: spacing(34),
          }}
        >
          <img
            src={photoUrl}
            alt="Profile"
            style={{
              width: '100%',
              height: '100%',
              objectFit: 'cover',
              borderRadius: '50%',
            }}
          />
        </DSAvatar>
        <DSTabHeaderTypography>{name}</DSTabHeaderTypography>
        <DSValueTypography>{email}</DSValueTypography>
        <DSSecondaryButton
          onClick={() => handleEitProfile()}
          color="secondary"
          variant="contained"
          sx={{
            fontSize: spacing(2),
            borderRadius: 'var(--Corner-Full, 1000px)',
            px: spacing(4),
            py: spacing(1),
            width: spacing(30),
            boxShadow:
              '0px 1px 5px 0px rgba(0, 0, 0, 0.12), 0px 2px 2px 0px rgba(0, 0, 0, 0.14), 0px 3px 1px -2px rgba(0, 0, 0, 0.20)',
          }}
        >
          Edit Profile
        </DSSecondaryButton>
      </DSBox>
      <DSBox
        sx={{
          display: 'flex',
          justifyContent: 'center',
          flexShrink: 0,
        }}
      >
        <DSGradientViewPaper
          sx={{
            flexDirection: 'column',
            width: {
              sx: spacing(5),
              sm: spacing(45),
              md: spacing(87),
              lg: spacing(97),
              xl: spacing(107),
            },
            height: 'auto',
          }}
        >
          <DSBox>
            <DSLabelTypography>Description: </DSLabelTypography>
            <DSValueTypography>{description}</DSValueTypography>
          </DSBox>
          <DSBox
            sx={{
              display: 'flex',
              justifyContent: 'space-between',
              flexDirection: { sm: 'column', md: 'row' },
              gap: { sm: spacing(2), md: spacing(0) },
              alignSelf: 'stretch',
              alignItems: 'flex-start',
            }}
          >
            <DSBox
              sx={{ display: 'flex', flexDirection: 'row', gap: spacing(2) }}
            >
              <DSAvatar
                sx={{
                  width: spacing(5.5),
                  height: spacing(5.5),
                }}
              >
                <Flag
                  code={country}
                  style={{
                    width: '100%',
                    height: '100%',
                    objectFit: 'cover',
                    borderRadius: '50%',
                  }}
                  alt={`${country} Flag`}
                />
              </DSAvatar>

              <DSBox sx={{ display: 'flex', flexDirection: 'column' }}>
                <DSLabelTypography>Country: </DSLabelTypography>
                <DSValueTypography>{country}</DSValueTypography>
              </DSBox>
            </DSBox>
            <DSBox
              sx={{
                display: 'flex',
                width: spacing(38),
                alignItems: 'flex-start',
                flexDirection: 'column',
                gap: spacing(2),
              }}
            >
              <DSLabelTypography>Social Media:</DSLabelTypography>
              <SocialChipStack socialMedia={socialMedia} />
            </DSBox>
          </DSBox>
        </DSGradientViewPaper>
      </DSBox>
    </DSBox>
  );
};

export default Profile;
