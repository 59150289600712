import React from 'react';
import { Paper, useTheme } from '@mui/material';

const DSGradientViewPaper = (props: React.ComponentProps<typeof Paper>) => {
  const { spacing } = useTheme();
  return (
    <Paper
      {...props}
      sx={{
        ...props.sx,
        flexShrink: 0,
        display: 'flex',
        alignItems: 'flex-start',
        background:
          'var(--Background-first-layer, linear-gradient(0deg, #111 38%, #212121 100%));',
        boxShadow:
          '0px 1px 3px 0px rgba(0, 0, 0, 0.12), 0px 1px 1px 0px rgba(0, 0, 0, 0.14), 0px 2px 1px -1px rgba(0, 0, 0, 0.20)',
        borderRadius: spacing(3.5),
        gap: spacing(3),
        padding: spacing(4),
        alignSelf: 'stretch',
      }}
    />
  );
};

export default DSGradientViewPaper;
