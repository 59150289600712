import firebase from 'firebase/compat/app';
import firebaseConfig from './firebaseConfig';
import 'firebase/compat/auth';
import 'firebase/compat/firestore';

class FirebaseService {
  db: firebase.firestore.Firestore;

  private auth: firebase.auth.Auth;

  constructor() {
    firebase.initializeApp(firebaseConfig);
    this.db = firebase.firestore();
    this.auth = firebase.auth();
  }

  public onAuthStateChanged(callback: (user: firebase.User | null) => void) {
    return this.auth.onAuthStateChanged(callback);
  }

  public getToken() {
    return this.auth.currentUser?.getIdToken();
  }

  public impersonate(idToken: string) {
    return this.auth.signInWithCustomToken(idToken);
  }

  public getUserId() {
    return this.auth.currentUser?.uid;
  }

  public resetUser() {
    this.auth.currentUser?.getIdToken(true);
  }

  public signOut() {
    return this.auth.signOut();
  }

  public async signInWithGoogle() {
    if (!this.auth) {
      throw new Error('Firebase Auth is not initialized.');
    }

    const provider = new firebase.auth.GoogleAuthProvider();
    return this.auth.signInWithPopup(provider);
  }

  public async createUserWithEmailAndPassword(email: string, password: string) {
    try {
      const userCredential = await this.auth.createUserWithEmailAndPassword(
        email,
        password,
      );
      const user = userCredential.user;
      await user?.sendEmailVerification();
      if (user && !user.emailVerified) {
        window.alert('Please check your inbox and verify your email address.');
      }
      return user;
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
    } catch (error: any) {
      if (error.code === 'auth/email-already-in-use') {
        window.alert(
          'Registration Error ❌: This email is already registered. Please log in instead.',
        );
      } else if (error.code === 'auth/invalid-email') {
        window.alert(
          'Invalid Email ❌: The email address is not properly formatted. Please try again.',
        );
      } else if (error.code === 'auth/weak-password') {
        window.alert(
          'Weak Password ❌: Your password is too weak. Please choose a stronger password.',
        );
      } else {
        window.alert(
          `Registration Error ❌: ${
            error.message || 'An unknown error occurred. Please try again.'
          }`,
        );
      }
      console.error('Error during registration:', error);
      throw error;
    }
  }

  public async signInWithEmailAndPassword(email: string, password: string) {
    try {
      const userCredential = await this.auth.signInWithEmailAndPassword(
        email,
        password,
      );

      const user = userCredential.user;

      if (user && !user.emailVerified) {
        window.alert(
          'Your email is not verified. Please click resend verification email.',
        );
      }
      return user;
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
    } catch (error: any) {
      const errorMessage =
        error?.message || 'An error occurred during sign-in.';
      window.alert(`Sign-In Error ❌: ${errorMessage}`);
      throw error;
    }
  }

  public async resendVerificationEmail() {
    const user = this.auth.currentUser;
    if (user && !user.emailVerified) {
      try {
        await user.sendEmailVerification();
        window.alert('Verification email resent. Please check your inbox.');
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
      } catch (error: any) {
        window.alert(
          `Error sending verification email: ${
            error.message || 'Please try again later.'
          }`,
        );
      }
    } else {
      window.alert('User not logged in or email already verified.');
    }
  }

  public async sendPasswordResetEmail(email: string) {
    try {
      await this.auth.sendPasswordResetEmail(email);
      window.alert(
        'Password Reset Email Sent: Check your email for instructions to reset your password.',
      );
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
    } catch (error: any) {
      if (error.code === 'auth/user-not-found') {
        window.alert('Error: No user found with this email address.');
      } else if (error.code === 'auth/invalid-email') {
        window.alert('Error: Please enter a valid email address.');
      } else {
        window.alert(
          `Error: ${
            error.message || 'An unknown error occurred. Please try again.'
          }`,
        );
      }
    }
  }
}

const firebaseService = new FirebaseService();
export default firebaseService;
