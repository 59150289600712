import { useTheme } from '@mui/material';
import { useCallback, useState } from 'react';

export type UseKlydoColorStackProps = {
  onChangeColor: (index: number) => void;
};
const useKlydoColorStack = ({ onChangeColor }: UseKlydoColorStackProps) => {
  const { spacing } = useTheme();
  const [selectedIndex, setSelectedIndex] = useState<number>(0);
  const [openColorPicker, setOpenColorPicker] = useState<boolean>(false);
  const handleColorClick = useCallback(
    (index: number) => {
      onChangeColor(index);
      setSelectedIndex(index);
    },
    [onChangeColor],
  );

  return {
    spacing,
    handleColorClick,
    selectedIndex,
    openColorPicker,
    setOpenColorPicker,
  };
};

export default useKlydoColorStack;
