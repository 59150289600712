import { useRef, useState } from 'react';
import { uploadFile } from '../../../utils/uploadFile';
import { useNavigate } from 'react-router-dom';
import { ProfileFormProps } from '../components/ProfileForm';
import { useTheme } from '@mui/material';
import { SocialMedia } from '../../../api/user';

const useProfileForm = (props: ProfileFormProps) => {
  const navigate = useNavigate();
  const theme = useTheme();
  const [name, setName] = useState(props.name || '');
  const [country, setCountry] = useState(props?.country || '');
  const [socialMedia, setSocialMedia] = useState<SocialMedia[]>(
    props?.socialMedia || [],
  );
  const [description, setDescription] = useState(props?.description || '');
  const [photoUrl, setPhotoUrl] = useState(
    props?.photoUrl?.replace('s96-c', 's400-c') ||
      'https://ui-avatars.com/api/?name=User',
  );

  const fileInputRef = useRef<HTMLInputElement>(null);

  const handleFileUpload = async (fileUrl: string) => {
    uploadFile({
      file: fileUrl,
      folder: 'profile',
      upload_preset: 'GiftPicture',
    })
      .then((response) => {
        setPhotoUrl(response.secure_url);
      })
      .catch((error) => {
        console.error('Upload error:', error);
      });
  };

  const readAndPreviewFile = (file: File): void => {
    const reader = new FileReader();
    reader.onload = () => {
      handleFileUpload(reader.result as string);
      setPhotoUrl(reader.result as string);
    };
    reader.readAsDataURL(file);
  };

  const handleFileSelection = (
    event: React.ChangeEvent<HTMLInputElement>,
  ): void => {
    const file = event.target.files?.[0];
    if (!file) return;

    readAndPreviewFile(file);
  };

  const handleNameChange = (senderName: string) => {
    if (senderName.length > 14) return alert('Name is too long');
    setName(senderName);
  };

  const handleRemoveImage = () => {
    if (fileInputRef.current) {
      fileInputRef.current.value = '';
    }
    setPhotoUrl('');
  };

  const handleSave = () => {
    props.onSave({
      name,
      email: props.email,
      description,
      country,
      socialMedia,
      photoUrl,
    });
    navigate('/profile');
  };

  const handleAddSocialMedia = () => {
    setSocialMedia([...socialMedia, { link: '', network: '' }]);
  };

  const handleRemoveSocialMedia = (index: number) => {
    setSocialMedia(socialMedia.filter((_, i) => i !== index));
  };

  return {
    name,
    setName,
    country,
    setCountry,
    socialMedia,
    setSocialMedia,
    description,
    setDescription,
    photoUrl,
    setPhotoUrl,
    fileInputRef,
    handleFileSelection,
    handleNameChange,
    handleRemoveImage,
    handleSave,
    handleAddSocialMedia,
    handleRemoveSocialMedia,
    theme,
  };
};

export default useProfileForm;
