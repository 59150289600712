import React from 'react';
import { Typography, useTheme } from '@mui/material';

const DSTabHeaderTypography = (
  props: React.ComponentProps<typeof Typography>,
) => {
  const { palette } = useTheme();
  return <Typography variant="h3" color={palette.text.primary} {...props} />;
};

export default DSTabHeaderTypography;
