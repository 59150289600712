import React, { forwardRef } from 'react';
import { TextField, useTheme } from '@mui/material';

const DSTextField = forwardRef<
  HTMLInputElement,
  React.ComponentProps<typeof TextField>
>((props, ref) => {
  const { spacing, palette } = useTheme();
  return (
    <TextField
      ref={ref}
      {...props}
      sx={{
        ...props.sx,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        padding: 'var(--1, 8px) 16px',
        borderRadius: '28px',
        background: 'rgba(255, 255, 255, 0.09)',
        '.MuiInputBase-input': {
          fontSize: spacing(2),
          padding: spacing(1),
          '&::placeholder': {
            color: palette.text.disabled,
          },
        },
        '.MuiOutlinedInput-notchedOutline': {
          border: 'none',
        },
        '&:hover .MuiOutlinedInput-notchedOutline': {
          border: 'none',
        },
        '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
          border: 'none',
        },
      }}
    />
  );
});

export default DSTextField;
