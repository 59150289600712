export type ParseUrlInput = {
  url: string;
  isStatic?: boolean;
  size?: number;
};

export const parseCloudinaryUrl = (input: ParseUrlInput) => {
  let { url } = input;
  if (!url) return;
  const { isStatic, size } = input;
  const type = url.split('.').pop();
  const params = `c_scale,w_${size}`;
  const framesParams = 'so_auto';
  if (isStatic) {
    url = url.replace(/\.(mp4|webp|gif)$/, '.jpg');
    if (url!.includes('image/upload') || url.includes('video/upload')) {
      url = url.replace('/upload/', `/upload/${framesParams}/`);
    } else {
      url = url.replace('/klydoclock/', `/klydoclock/${framesParams}/`);
    }
    return url;
  }
  if (type === 'mp4') {
    if (size) {
      if (url!.includes('image/upload') || url.includes('video/upload')) {
        url = url.replace('/upload/', `/upload/${params}/`);
      } else {
        url = url.replace('/klydoclock/', `/klydoclock/${params}/`);
      }
    }
  }
  return url;
};

export const resizeCloudinary = (
  url: string | undefined,
  size: 200 | 150 | 96 | 48,
) => {
  if (!url) return '';
  if (url!.includes('image/upload') || url.includes('video/upload')) {
    return url.replace('/upload/', '/upload/c_scale,w_' + size + '/');
  }
  return url.replace('/klydoclock/', '/klydoclock/c_scale,w_' + size + '/');
};

type ViewType = 'video' | 'image' | 'none';
const imgTypes = [
  'jpg',
  'gif',
  'avif',
  'apng',
  'png',
  'ico',
  'cur',
  'jpeg',
  'jfif',
  'pjpeg',
  'pjp',
  'tif',
  'webp',
  'bmp',
];
const videoTypes = ['mp4', 'mpge', 'avi', 'mov', 'mkv', 'webm', 'ogg', 'ogv'];
export function isVideo(url: string): ViewType {
  if (url.startsWith('data'))
    return url.startsWith('data:video')
      ? 'video'
      : url.startsWith('data:image')
        ? 'image'
        : 'none';
  const arr = url.split('.');
  const typ = arr[arr.length - 1];
  return imgTypes.includes(typ)
    ? 'image'
    : videoTypes.includes(typ)
      ? 'video'
      : 'none';
}

export function formatDistanceToNow(date: Date) {
  const now = new Date();
  const givenDate = new Date(date);
  const diffInMilliseconds = now.getTime() - givenDate.getTime();

  if (isNaN(diffInMilliseconds)) {
    return 'Invalid date';
  }

  const diffInSeconds = Math.floor(diffInMilliseconds / 1000);
  const diffInMinutes = Math.floor(diffInSeconds / 60);
  const diffInHours = Math.floor(diffInMinutes / 60);
  const diffInDays = Math.floor(diffInHours / 24);
  const diffInMonths = Math.floor(diffInDays / 30);
  const diffInYears = Math.floor(diffInMonths / 12);

  if (diffInSeconds < 60) {
    return `${diffInSeconds} seconds ago`;
  } else if (diffInMinutes < 60) {
    return `${diffInMinutes} minutes ago`;
  } else if (diffInHours < 24) {
    return `${diffInHours} hours ago`;
  } else if (diffInDays < 30) {
    return `${diffInDays} days ago`;
  } else if (diffInMonths < 12) {
    return `${diffInMonths} months ago`;
  } else {
    return `${diffInYears} years ago`;
  }
}
