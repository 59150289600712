import { useQueryClient } from '@tanstack/react-query';
import packApiClient from '../../../api/packs';
import useKlydoMutation from '../../../hooks/useKlydoMutation';

const useRemovePackMutation = () => {
  const queryClient = useQueryClient();

  return useKlydoMutation({
    mutationKey: ['packs'],
    mutationFn: (packId: string) => packApiClient.deletePack(packId),
    onSuccess: () => queryClient.refetchQueries({ queryKey: ['packs'] }),
  });
};

export default useRemovePackMutation;
