import DSBox from '../../../design-system/DSBox';
import DSGrid from '../../../design-system/DSGrid';
import DSDialog from '../../../design-system/DSDialog';
import { DSTypography } from '../../../design-system/Typography';
import DSDialogActions from '../../../design-system/DSDialogActions';
import DSPrimaryButton from '../../../design-system/DSPrimaryButtom';
import DSSecondaryButton from '../../../design-system/DSSecondaryButton';
import usePickKlydosModal from '../hooks/usePickKlydosModal';
import { KlydoUnion } from '../../../api/klydo';
import KlydoGridItem from '../../Klydos/KlydosList/components/KlydoGridItem';

interface PickKlydosModalProps {
  isOpen: boolean;
  onClose: () => void;
  onPick: (selectedKlydos: KlydoUnion[]) => void;
}

const PickKlydosModal = ({ isOpen, onClose, onPick }: PickKlydosModalProps) => {
  const {
    klydos,
    isLoading,
    selectedKlydos,
    handleSave,
    toggleKlydoSelection,
  } = usePickKlydosModal({ onPick, onClose });

  return (
    <DSDialog open={isOpen} onClose={onClose} title="Pick Klydos">
      <DSBox
        sx={{
          padding: 3,
          display: 'flex',
          flexDirection: 'column',
          gap: 2,
        }}
      >
        <DSTypography
          variant="h6"
          sx={{ marginBottom: 2, textAlign: 'center' }}
        >
          Pick Klydos
        </DSTypography>
        {isLoading ? (
          <DSTypography variant="body2" sx={{ textAlign: 'center' }}>
            Loading Klydos...
          </DSTypography>
        ) : klydos && klydos.length > 0 ? (
          <DSBox
            sx={{
              width: '100%',
              maxHeight: '400px',
              overflowY: 'auto',
            }}
          >
            <DSGrid container spacing={2}>
              {klydos.map((klydo) => (
                <DSGrid container sx={{ xs: 12, sm: 6, md: 4 }} key={klydo.id}>
                  <KlydoGridItem
                    klydo={klydo}
                    isSelected={selectedKlydos.some(
                      (selected) => selected.id === klydo.id,
                    )}
                    onSelect={toggleKlydoSelection}
                  />
                </DSGrid>
              ))}
            </DSGrid>
          </DSBox>
        ) : (
          <DSTypography variant="body2" sx={{ textAlign: 'center' }}>
            No Klydos available.
          </DSTypography>
        )}
      </DSBox>
      <DSDialogActions sx={{ justifyContent: 'space-between', padding: 3 }}>
        <DSSecondaryButton onClick={onClose}>Cancel</DSSecondaryButton>
        <DSPrimaryButton
          variant="contained"
          color="primary"
          onClick={handleSave}
          disabled={selectedKlydos.length === 0}
        >
          Save
        </DSPrimaryButton>
      </DSDialogActions>
    </DSDialog>
  );
};

export default PickKlydosModal;
