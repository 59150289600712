import ProfileForm from './ProfileForm';
import firebase from 'firebase/compat/app';
import useSignupForm from '../hooks/useSignupForm';
import { User } from '../../../api/user';

const SignupForm = ({
  setUser,
  authUser,
}: {
  authUser: firebase.User;
  setUser: (user: User) => void;
}) => {
  const {
    name,
    email,
    description,
    country,
    socialMedia,
    photoUrl,
    handleSave,
  } = useSignupForm(authUser, setUser);

  return (
    <ProfileForm
      name={name}
      email={email}
      description={description}
      country={country}
      photoUrl={photoUrl}
      socialMedia={socialMedia}
      onSave={handleSave}
    />
  );
};

export default SignupForm;
