import { useTheme } from '@mui/material';
import { keyframes } from '@emotion/react';

const usePendulum = () => {
  const { zIndex } = useTheme();
  const swingAnimation = keyframes`
  0% { transform: rotate(-25deg); }
  50% { transform: rotate(25deg); }
  100% { transform: rotate(-25deg); }
`;

  const rodSwingAnimation = keyframes`
  0% { transform: rotate(-6deg); }
  50% { transform: rotate(6deg); }
  100% { transform: rotate(-6deg); }
`;
  return {
    zIndex,
    swingAnimation,
    rodSwingAnimation,
  };
};

export default usePendulum;
