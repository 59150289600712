import { useTheme } from '@mui/material';

const useClockView = () => {
  const { zIndex } = useTheme();

  return {
    zIndex,
  };
};

export default useClockView;
