import { useState } from 'react';

const useKlydoItemMenuButton = () => {
  const [anchorElement, setAnchorElement] = useState<null | HTMLElement>(null);
  const handleMenuClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorElement(event.currentTarget);
  };
  const handleMenuClose = () => {
    setAnchorElement(null);
  };
  const isMenuOpen = Boolean(anchorElement);

  return {
    anchorElement,
    handleMenuClick,
    handleMenuClose,
    isMenuOpen,
  };
};

export default useKlydoItemMenuButton;
