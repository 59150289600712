import React from 'react';
import { CardMedia, useTheme } from '@mui/material';

const DSPackCardMedia = (props: React.ComponentProps<typeof CardMedia>) => {
  const { spacing } = useTheme();
  return (
    <CardMedia
      {...props}
      sx={{
        width: spacing(37),
        height: spacing(37),
        borderRadius: spacing(10),
        objectFit: 'cover',
      }}
    />
  );
};

export default DSPackCardMedia;
