import KlydoPreview from '../../../../components/klydoPreview/KlydoPreview';
import DSBox from '../../../../design-system/DSBox';
import { DSTypography } from '../../../../design-system/Typography';
import useKlydoGridItem from '../hooks/useKlydoGridItem';
import KlydoItemMenuButton from './KlydoItemMenuButton';
import { KLYDO_YELLOW } from '../../../../consts/colors';
import DSDivider from '../../../../design-system/DSDivider';
import { formatDistanceToNow } from '../../../../utils';
import KlydoStatus from './KlydoStatus';
import { KlydoType, KlydoUnion } from '../../../../api/klydo';

type KlydoGridItemProps = {
  klydo: KlydoUnion;
  isSelected?: boolean;
  onSelect?: (klydo: KlydoUnion) => void;
};

const KlydoGridItem = ({ klydo, isSelected, onSelect }: KlydoGridItemProps) => {
  const {
    isGridItemStatic,
    handleMouseEnter,
    handleMouseLeave,
    deleteKlydoMutation,
    handleSubmitKlydo,
    theme,
    klydoNameLengthLimit,
  } = useKlydoGridItem();

  return (
    <DSBox
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}
      onClick={() => onSelect && onSelect(klydo)}
      sx={{
        width: theme.spacing(35),
        height: 'auto',
        borderRadius: theme.spacing(3),
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
        backgroundColor: '#1C1C1E',
        padding: theme.spacing(2),
        cursor: 'pointer',
        border: isSelected ? `2px solid ${KLYDO_YELLOW}` : 'none',
      }}
      key={klydo.id}
    >
      <DSBox
        sx={{
          width: '42%',
          height: theme.spacing(23),
          position: 'relative',
          boxSizing: 'border-box',
          overflow: 'hidden',
        }}
      >
        <KlydoPreview klydo={klydo} isAnimationStatic={isGridItemStatic} />
      </DSBox>
      <DSBox
        sx={{
          flex: 1,
          width: '100%',
          marginLeft: theme.spacing(2),
        }}
      >
        {klydo.name && (
          <DSTypography
            sx={{
              fontSize: theme.spacing(3),
              alignSelf: 'self-start',
              marginBottom: theme.spacing(1),
              fontWeight: 'bold',
            }}
          >
            {klydo.name.length > klydoNameLengthLimit
              ? `${klydo.name.substring(0, klydoNameLengthLimit)}...`
              : klydo.name}
          </DSTypography>
        )}
        {klydo.createdAt && (
          <DSTypography
            sx={{ fontSize: theme.spacing(2), alignSelf: 'self-start' }}
          >
            {formatDistanceToNow(klydo.createdAt)}
          </DSTypography>
        )}
        <DSBox mb={theme.spacing(4)} />
        <DSDivider
          flexItem
          sx={{
            borderColor: '#808080',
            alignSelf: 'center',
            width: '90%',
            borderWidth: 1,
          }}
        />
        <DSBox
          sx={{
            display: 'flex',
            flexDirection: 'row-reverse',
            alignItems: 'center',
            gap: theme.spacing(1),
            mt: theme.spacing(1),
          }}
        >
          <KlydoItemMenuButton
            klydo={klydo}
            onDelete={deleteKlydoMutation}
            onSubmit={handleSubmitKlydo}
          />

          <KlydoStatus
            state={
              klydo.type === KlydoType.Approved
                ? KlydoType.Approved
                : klydo?.reviewStatus?.state
            }
          />
        </DSBox>
      </DSBox>
    </DSBox>
  );
};

export default KlydoGridItem;
