import { createContext } from 'react';
import firebase from 'firebase/compat/app';
import { User } from '../api/user';

export type AppContextType = {
  user: User;
  authUser: firebase.User | null;
  setUser: React.Dispatch<React.SetStateAction<User | null>>;
  isAdmin: boolean;
  isImpersonating: boolean;
};

export const AppContext = createContext<AppContextType>({} as AppContextType);
