import { useMediaQuery, useTheme } from '@mui/material';
import { useState } from 'react';
import { useNavigate } from 'react-router-dom';

const useNavigationDrawer = () => {
  const navigate = useNavigate();
  const { breakpoints, zIndex, palette, spacing } = useTheme();
  const isMobile = useMediaQuery(breakpoints.down('md'));
  const [isDrawerOpen, setIsDrawerOpen] = useState(false);

  const toggleDrawer = (open: boolean) => {
    setIsDrawerOpen(open);
  };

  const [selectedRoute, setSelectedRoute] = useState('/dashboard');

  const handleClick = (route: string) => {
    setSelectedRoute(route);
    navigate(route);
    if (isMobile) toggleDrawer(false);
  };

  return {
    navigate,
    isMobile,
    isDrawerOpen,
    toggleDrawer,
    handleClick,
    selectedRoute,
    zIndex,
    palette,
    spacing,
  };
};

export default useNavigationDrawer;
