import { useTheme } from '@mui/material';
import useRemovePackMutation from './useRemovePackMutation';
import useUpdatePackMutation from './useUpdatePackMutation';
import { PackReviewStates } from '../../../api/packs';
import { DraftPack } from '../../../api/packs';

const usePackCard = () => {
  const packNameLengthLimit = 27;
  const theme = useTheme();
  const { mutate: deletePackMutation } = useRemovePackMutation();
  const { mutate: updatePackMutation } = useUpdatePackMutation();

  const handleSubmitPack = (pack: DraftPack) => {
    updatePackMutation({
      packId: pack.id,
      pack: {
        ...pack,
        reviewStatus: {
          state: PackReviewStates.Pending,
          message: '',
        },
      },
    });
  };
  return {
    deletePackMutation,
    theme,
    packNameLengthLimit,
    updatePackMutation,
  };
};

export default usePackCard;
