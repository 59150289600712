import DSBox from '../../../../design-system/DSBox';
import KlydoGrid from './KlydoGrid';
import useKlydosView from '../hooks/useKlydosList';
import DSPrimaryButton from '../../../../design-system/DSPrimaryButtom';
import DSTabGradientViewPaper from '../../../../design-system/Paper/DSTabGradientViewPaper';
import DSTabHeaderTypography from '../../../../design-system/Typography/DSSTabHeaderTypography';

const Klydos = () => {
  const {
    data,
    isLoading,
    fetchNextPage,
    hasNextPage,
    isFetchingNextPage,
    spacing,
    navigate,
  } = useKlydosView();

  return (
    <DSTabGradientViewPaper
      sx={{
        display: 'flex',
        flexDirection: 'column',
        margin: spacing(3),
      }}
    >
      <DSBox
        sx={{
          display: 'flex',
          justifyContent: 'space-between',
          flexDirection: 'row',
          alignItems: 'center',
          paddingTop: spacing(5),
          paddingX: spacing(4),
          paddingBottom: spacing(7),
        }}
      >
        <DSTabHeaderTypography>Klydos</DSTabHeaderTypography>

        <DSPrimaryButton
          onClick={() => navigate('/create-klydo')}
          sx={{ padding: '14px var(--5, 40px)' }}
        >
          Create
        </DSPrimaryButton>
      </DSBox>
      <DSBox
        sx={{ display: 'flex', alignItems: 'center', flexDirection: 'column' }}
      >
        <KlydoGrid
          isLoading={isLoading}
          klydos={data}
          fetchNextPage={fetchNextPage}
          hasNextPage={hasNextPage}
          isFetchingNextPage={isFetchingNextPage}
        />
      </DSBox>
    </DSTabGradientViewPaper>
  );
};
export default Klydos;
