import { formatDistanceToNow } from '../../../utils';
import PackItemMenuButton from './PackItemMenuButton';
import { PackReviewStates, PackType, PackUnion } from '../../../api/packs';
import usePackCard from '../hooks/usePackCard';
import PackStatus from './PackStatus';
import DSBox from '../../../design-system/DSBox';
import { DSPackCard, DSPackCardMedia } from '../../../design-system/Pack';
import { DSCardActions, DSCardContent } from '../../../design-system/Card';
import {
  DSTitleTypography,
  DSSubtitleTypography,
} from '../../../design-system/Typography';

export type PackCardProps = {
  pack: PackUnion;
};

const PackCard = ({ pack }: PackCardProps) => {
  const { deletePackMutation, theme, packNameLengthLimit, updatePackMutation } =
    usePackCard();
  return (
    <DSPackCard>
      <DSBox sx={{ justifyContent: 'center', display: 'flex' }}>
        <DSPackCardMedia image={pack.cover} />
      </DSBox>
      <DSBox>
        <DSCardContent sx={{ textAlign: 'left', paddingBottom: 0 }}>
          <DSBox height={theme.spacing(6)}>
            <DSTitleTypography
              sx={{
                whiteSpace: 'normal',
                wordBreak: 'break-word',
              }}
            >
              {pack.name.length > packNameLengthLimit
                ? `${pack.name.substring(0, packNameLengthLimit)}...`
                : pack.name}
            </DSTitleTypography>
          </DSBox>

          <DSSubtitleTypography>
            {formatDistanceToNow(pack.createdAt)}
          </DSSubtitleTypography>
        </DSCardContent>
        <DSCardActions sx={{ justifyContent: 'space-between', paddingTop: 0 }}>
          <PackStatus
            state={
              pack.type === PackType.Draft
                ? pack?.reviewStatus?.state
                : PackReviewStates.Published
            }
          />
          <PackItemMenuButton
            pack={pack}
            onDelete={deletePackMutation}
            onSubmit={updatePackMutation}
          />
        </DSCardActions>
      </DSBox>
    </DSPackCard>
  );
};

export default PackCard;
