import { useTheme } from '@mui/material';
import DSAvatar from '../../../design-system/DSAvatar';
import DSIconButton from '../../../design-system/DSIconButton';
import { DSTypography } from '../../../design-system/Typography';
import DSBox from '../../../design-system/DSBox';
import { KLYDO_BLACK, KLYDO_WHITE } from '../../../consts/colors';
import DSTextField from '../../../design-system/DSTextField';
import AddIcon from '@mui/icons-material/Add';
import ClearIcon from '@mui/icons-material/Clear';

import DSAppBar from '../../../design-system/DSAppBar';
import DSPaper from '../../../design-system/Paper/DSPaper';
import logo from '../../../images/klydo-yellow.png';
import DSButton from '../../../design-system/DSButtom';
import useProfileEditable from '../hooks/useProfileEditable';

const ProfileEditable = () => {
  const theme = useTheme();
  const {
    name,
    setName,
    description,
    setDescription,
    profileImage,
    country,
    setCountry,
    socialMedia,
    setSocialMedia,
    email,
    handleAddSocialMedia,
    handleRemoveSocialMedia,
    handleSave,
  } = useProfileEditable();

  return (
    <DSPaper
      sx={{
        overflow: 'hidden',
        minHeight: '100vh',
      }}
    >
      <DSAppBar
        position="fixed"
        color="transparent"
        sx={{
          cursor: 'pointer',
          backgroundColor: theme.palette.background.paper,
          paddingY: { xs: 2, md: 4 },
          display: 'flex',
          justifyContent: 'center',
          textAlign: 'center',
          alignItems: 'center',
          boxShadow: 'none',
        }}
      >
        <img width={'130'} src={logo} />
      </DSAppBar>
      <DSBox
        sx={{
          overflow: 'auto',
          backgroundColor: theme.palette.background.paper,
          paddingTop: { xs: 12, md: 15 },
          height: '100vh',
          paddingBottom: { xs: 10, md: 20 },
          display: 'flex',
          flexDirection: 'column',
          textAlign: 'center',
          alignItems: 'center',
          justifyContent: 'start',
          overflowX: 'hidden',
        }}
      >
        <DSTypography
          lineHeight={1.2}
          sx={{
            fontSize: { xs: 35, md: 47.52 },
            maxWidth: { xs: '270px', md: '1080px' },
            fontFamily: 'Gilroy-Bold',
            fontWeight: 400,
          }}
        >
          Your Profile
        </DSTypography>
        <DSBox
          width={'100%'}
          sx={{
            backgroundColor: theme.palette.background.paper,
            paddingTop: { xs: 2, md: 5 },
            paddingBottom: { xs: 10, md: 0 },
          }}
        >
          <DSBox
            sx={{
              display: 'flex',
              justifyContent: 'center',
              flexDirection: { xs: 'column', md: 'row-reverse' },
              alignItems: 'center',
              textAlign: 'left',
              gap: { xs: 0.5, md: 5 },
            }}
          >
            <DSBox
              sx={{
                flexDirection: 'column',
                alignItems: 'center',
                justifyContent: 'center',
                textAlign: 'center',
                position: 'relative',
              }}
            >
              <DSAvatar
                sx={{
                  width: { xs: 120, md: 140 },
                  height: { xs: 120, md: 140 },
                  cursor: 'pointer',
                }}
                onClick={() => {
                  alert('Profile picture update coming soon!');
                }}
              >
                <img
                  src={profileImage || 'default-avatar.png'}
                  alt="Profile"
                  style={{
                    width: '100%',
                    height: '100%',
                    objectFit: 'cover',
                    borderRadius: '50%',
                    border: '3px solid white',
                    backgroundColor: KLYDO_BLACK,
                  }}
                />
              </DSAvatar>
            </DSBox>
            <DSBox
              sx={{
                display: 'flex',
                justifyContent: 'center',
                flexDirection: 'column',
                textAlign: 'left',
                width: { xs: '80%', md: '30%' },
              }}
            >
              <DSBox py={1}>
                <DSTypography
                  variant="h6"
                  lineHeight={'18.96px'}
                  fontSize={'16px'}
                  fontWeight={400}
                  color={'#C9C9C9'}
                  fontFamily={'Gilroy-SemiBold'}
                >
                  Name
                </DSTypography>
                <DSTextField
                  value={name}
                  onChange={(e) => setName(e.target.value)}
                  fullWidth
                />
              </DSBox>
              <DSBox py={1}>
                <DSTypography
                  variant="h6"
                  lineHeight={'18.96px'}
                  fontSize={'16px'}
                  fontWeight={400}
                  color={'#C9C9C9'}
                  fontFamily={'Gilroy-SemiBold'}
                >
                  Email (Non-editable)
                </DSTypography>
                <DSTextField value={email} disabled fullWidth />
              </DSBox>
              <DSBox py={1}>
                <DSTypography
                  variant="h6"
                  lineHeight={'18.96px'}
                  fontSize={'16px'}
                  fontWeight={400}
                  color={'#C9C9C9'}
                  fontFamily={'Gilroy-SemiBold'}
                >
                  Description
                </DSTypography>
                <DSTextField
                  value={description}
                  onChange={(e) => setDescription(e.target.value)}
                  multiline
                  rows={3}
                  fullWidth
                />
              </DSBox>
              <DSBox py={1}>
                <DSTypography
                  variant="h6"
                  lineHeight={'18.96px'}
                  fontSize={'16px'}
                  fontWeight={400}
                  color={'#C9C9C9'}
                  fontFamily={'Gilroy-SemiBold'}
                >
                  Country
                </DSTypography>
                <DSTextField
                  value={country}
                  onChange={(e) => setCountry(e.target.value)}
                  fullWidth
                />
              </DSBox>
              <DSBox py={1}>
                <DSTypography
                  variant="h6"
                  lineHeight={'18.96px'}
                  fontSize={'16px'}
                  fontWeight={400}
                  color={'#C9C9C9'}
                  fontFamily={'Gilroy-SemiBold'}
                >
                  Social Media
                </DSTypography>
                {socialMedia.map((sm, index) => (
                  <DSBox key={index} display="flex" alignItems="center" gap={2}>
                    <DSTextField
                      value={sm.network}
                      onChange={(e) =>
                        setSocialMedia(
                          socialMedia.map((item, i) =>
                            i === index
                              ? { ...item, network: e.target.value }
                              : item,
                          ),
                        )
                      }
                      fullWidth
                    />
                    <DSTextField
                      value={sm.link}
                      onChange={(e) =>
                        setSocialMedia(
                          socialMedia.map((item, i) =>
                            i === index
                              ? { ...item, link: e.target.value }
                              : item,
                          ),
                        )
                      }
                      fullWidth
                    />
                    <DSIconButton
                      sx={{ alignSelf: 'flex-start' }}
                      onClick={() => handleRemoveSocialMedia(index)}
                    >
                      <ClearIcon />
                    </DSIconButton>
                  </DSBox>
                ))}
                <DSButton
                  sx={{ color: KLYDO_WHITE, textAlign: 'left' }}
                  onClick={handleAddSocialMedia}
                >
                  <AddIcon /> Add Social Media
                </DSButton>
              </DSBox>
            </DSBox>
          </DSBox>
        </DSBox>
        <DSBox display="flex" gap={2} justifyContent="center" py={3}>
          <DSButton variant="contained" onClick={handleSave}>
            Save
          </DSButton>
        </DSBox>
      </DSBox>
    </DSPaper>
  );
};

export default ProfileEditable;
