import firebaseService from '../firebase/firebaseService';
import axios, { AxiosProgressEvent } from 'axios';

export const baseUrl =
  !process.env.NODE_ENV || process.env.NODE_ENV === 'development'
    ? 'https://api-dev.klydoclock.com'
    : 'https://api.klydoclock.com';
// Unslash this for local debugging
// 'http://localhost:10010';

export const getToken = async () => {
  return firebaseService.getToken();
};

export const getUserId = async () => {
  return firebaseService.getUserId();
};
export type RequestInput = {
  url: string;
  params?: object;
  body?: object;
  onUploadProgress?: (progressEvent: AxiosProgressEvent) => void;
  signal?: AbortSignal;
};

export const baseClient = {
  get: async <T>({
    url,
    params,
    signal,
  }: Pick<RequestInput, 'url' | 'params' | 'signal'>): Promise<T> => {
    return await axios
      .get(baseUrl + url, {
        headers: {
          Authorization: `Bearer ${await getToken()}`,
        },
        params,
        signal,
      })
      .then((res) => {
        return res.data;
      });
  },
  put: async <T>({ url, params, body, signal }: RequestInput): Promise<T> => {
    return await axios
      .put(baseUrl + url, body, {
        headers: {
          Authorization: `Bearer ${await getToken()}`,
        },
        params,
        signal,
      })
      .then((res) => res.data);
  },
  post: async <T>({
    url,
    params,
    body,
    onUploadProgress,
    signal,
  }: RequestInput): Promise<T> => {
    return await axios
      .post(baseUrl + url, body, {
        headers: {
          Authorization: `Bearer ${await getToken()}`,
        },
        params,
        onUploadProgress,
        signal,
      })
      .then((res) => res.data);
  },
  delete: async <T>({ url, params, signal }: RequestInput): Promise<T> => {
    return await axios
      .delete(baseUrl + url, {
        headers: {
          Authorization: `Bearer ${await getToken()}`,
        },
        params,
        signal,
      })
      .then((res) => res.data);
  },
};
